const PERMISSIONS_CONSTANTS = {
    MANAGE_BILLING: "manage_billing",

    VIEW_ORGANIZATION_MEMBERS: "view_organization_members",
    MANAGE_ORGANIZATION_MEMBERS: "manage_organization_members",

    CREATE_ORGANIZATION: "create_organization",
    UPDATE_ORGANIZATION: "update_organization",
    DELETE_ORGANIZATION: "delete_organization",
    VIEW_ORGANIZATION: "view_organization",

    CREATE_PROJECT: "create_project",
    UPDATE_PROJECT: "update_project",
    DELETE_PROJECT: "delete_project",
    VIEW_PROJECT: "view_project",

    VIEW_PROJECT_MEMBERS: "view_project_members",
    MANAGE_PROJECT_MEMBERS: "manage_project_members",

    CREATE_CHECKLIST: "create_checklist",
    UPDATE_CHECKLIST: "update_checklist",
    DELETE_CHECKLIST: "delete_checklist",
    VIEW_CHECKLIST: "view_checklist",

    CREATE_RUN: "create_run",
    UPDATE_RUN: "update_run",
    DELETE_RUN: "delete_run",
    VIEW_RUN: "view_run"
}

export default PERMISSIONS_CONSTANTS;