import Link from 'next/link';
import { RunProgressComponent } from './run-progress.component';

export default function RunBuilderMenuComponent({ title }: { title: string }) {
  return (
    <>
      <div className="border-b-[1px] border-solid border-black/10 cl_primary_background">
        {/* Run progress */}
        <RunProgressComponent />

        <div className="max-w-[1440px] mx-auto w-full h-full px-4 md:px-10">
          <div className="flex items-center justify-between gap-4 h-16 z-[1]">
            {/* Left side */}
            <div className="flex items-center gap-2">
              {/* Back */}
              <Link
                href={'/assigned'}
                className="flex items-center justify-center min-w-[40px] w-10 h-10 rounded-full bg-white border-[1px] border-solid border-black/20"
              >
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.5649 0.43451C7.87732 0.746929 7.87732 1.25346 7.5649 1.56588L2.13059 7.0002L7.5649 12.4345C7.87732 12.7469 7.87732 13.2535 7.5649 13.5659C7.25248 13.8783 6.74595 13.8783 6.43353 13.5659L0.433533 7.56588C0.121114 7.25346 0.121114 6.74693 0.433533 6.43451L6.43353 0.43451C6.74595 0.12209 7.25248 0.12209 7.5649 0.43451Z"
                    fill="black"
                  />
                </svg>
              </Link>

              {/* Title */}
              <div className="text-lg font-semibold line-clamp-1">
                &nbsp;{title}
              </div>
            </div>

            {/* Right side */}
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}
