import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import BuilderLayoutTabs from './builder-layout-tabs.component';
import DesignMenuComponent from './menu/design-menu.component';
import ResponsesMenuComponent from './menu/responses-menu.component';
import ShareMenuComponent from './menu/share-menu.component';

export default function BuilderLayoutMenuComponent() {
  // Variables
  const router = useRouter();
  const checklistId = router.query.checklistId;

  // States
  const [activeTab, setActiveTab] = useState<string>('');

  useEffect(() => {
    // Set the tabId based on url
    if (router.pathname) {
      if (router.pathname.startsWith('/checklist/')) {
        setActiveTab('design');
      } else if (router.pathname.startsWith('/share/')) {
        setActiveTab('share');
      } else if (router.pathname.startsWith('/responses/')) {
        setActiveTab('responses');
      }
    }
  }, [router]);

  return (
    <>
      {/* Dynamic menu based on URL */}
      {activeTab == 'design' && <DesignMenuComponent />}
      {activeTab == 'share' && <ShareMenuComponent />}
      {activeTab == 'responses' && <ResponsesMenuComponent />}

      {/* Mobile view - tabs */}
      <div className="block md:hidden border-b-[1px] border-solid border-black/10">
        <div className="w-full h-10 px-8 flex  align-left">
          <BuilderLayoutTabs />
        </div>
      </div>
    </>
  );
}
