import { create } from 'zustand';

interface ErrorState {
    title: string;
    description: React.ReactNode | string;
    buttonText: string;
    displayFlag: boolean;
    open: (description: React.ReactNode | string, title?: string, buttonText?: string) => void;
    close: () => void;
}

export const useErrorStore = create<ErrorState>(set => ({
    title: '',
    description: '',
    buttonText: '',
    displayFlag: false,
    open: (description, title?, buttonText?) => set({ title, description, buttonText: buttonText, displayFlag: true }),
    close: () => set({ title: '', description: '', displayFlag: false })
}));
