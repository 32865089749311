export function removeTypename(obj: any) {
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            removeTypename(obj[i]);
        }
    } else if (obj !== null && obj.constructor === Object) {
        for (const key in obj) {
            if (key === '__typename') {
                delete obj[key];
            } else {
                removeTypename(obj[key]);
            }
        }
    }
}