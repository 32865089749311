import { gql } from "@apollo/client";

export const GET_AGENT_MANAGER_RESULTS_BY_ID_QUERY = gql`
    query getAgentManagerResultsById($agentManagerId: uuid!) {
        agent_managers(where: {id: {_eq: $agentManagerId}}) {
            agents {
                agent_results(order_by: {created_at: asc}) {
                id
                thoughts
                action
                action_input
                results
                is_final_answer
            }
            }
        }
    }  
`;

export const SUBSCRIBE_AGENT_MANAGER_BY_ID_SUBSCRIPTION = gql`
    subscription subscribeAgentManagerByIdSubscription($agentManagerId: uuid!) {
        agent_managers(where: {id: {_eq: $agentManagerId}}) {
          id
          name
          agents {
            id
            name
            agent_results(order_by: {created_at: asc}) {
              id
              thoughts
              action
              action_input
              results
              is_final_answer
            }
          }
          checklists {
            id
            title
          }
        }
    }
`;

export const GET_COUNT_OF_AGENT_MANAGERS_BY_ORG_ID = gql`
  query GetCountOfAgentManagersByOrgId($orgId: uuid!) {
    agent_managers_aggregate(where: {org_id: {_eq: $orgId}}) {
      aggregate {
        count
      }
    }
  }
`;