import { ChecklistStatusIndicator } from '@/types/status-indicators.type';
import { create } from 'zustand';

interface ChecklistStatusIndicatorsState {
    checklistStatusIndicators: ChecklistStatusIndicator[] | undefined;
    setChecklistStatusIndicators: (checklistStatusIndicators: ChecklistStatusIndicator[]) => void;
    reset: () => void;
}

export const useChecklistStatusIndicatorsStore = create<ChecklistStatusIndicatorsState>(set => ({
    checklistStatusIndicators: undefined,
    setChecklistStatusIndicators: (checklistStatusIndicators: ChecklistStatusIndicator[]) => {
        set({ checklistStatusIndicators })
    },
    reset: () => {
        set({ checklistStatusIndicators: undefined })
    }
}));
