import { RunChecklistTree } from "@/components/runs/run-by-id.component";
import { RunChecklist } from "@/types/run.type";

export function markAllChildAsCompletedOrNotCompleted(
    currentNode: RunChecklistTree,
    status: boolean,
    updatableRunChecklist: RunChecklist[],
    checklistStatusIndicatorId?: string) {
    if (currentNode?.checklist) {
        if (currentNode.checklist.is_completed !== status || currentNode.checklist.checklist_status_indicators_id != checklistStatusIndicatorId) {
            currentNode.checklist.is_completed = status;
            currentNode.checklist.checklist_status_indicators_id = checklistStatusIndicatorId;
            storeUpdatableChecklist(updatableRunChecklist, currentNode?.checklist)
        }
    }

    // Mark child as completed or not-completed
    if (currentNode?.children?.length > 0) {
        currentNode?.children?.forEach(childNode => {
            markAllChildAsCompletedOrNotCompleted(childNode, status, updatableRunChecklist, checklistStatusIndicatorId);
        });
    }
}

export function markParentAsNotCompleted(parentNode: RunChecklistTree | undefined, updatableRunChecklist: RunChecklist[]) {
    if (!parentNode?.checklist?.is_completed || !parentNode) {
        return;
    }

    // Mark parent node as not completed
    if (parentNode.checklist.is_completed === true) {
        parentNode.checklist.is_completed = false;
        storeUpdatableChecklist(updatableRunChecklist, parentNode?.checklist)
    }

    markParentAsNotCompleted(parentNode.parent, updatableRunChecklist);
}

export function markParentAsCompleted(parentNode: RunChecklistTree | undefined, updatableRunChecklist: RunChecklist[]) {
    if (!parentNode) {
        return;
    }

    const notCompletedChildNode = parentNode?.children?.find(
        childNode => !childNode?.checklist?.is_completed
    );
    if (notCompletedChildNode) {
        return;
    }

    // Mark parent node as not completed
    if (parentNode.checklist.is_completed !== true) {
        parentNode.checklist.is_completed = true;
        storeUpdatableChecklist(updatableRunChecklist, parentNode?.checklist)
    }

    markParentAsCompleted(parentNode.parent, updatableRunChecklist);
}

function storeUpdatableChecklist(updatableRunChecklist: RunChecklist[], { __typename, ...newRunChecklist }: RunChecklist) {
    updatableRunChecklist.push({
        id: newRunChecklist?.run_checklist_task_id,
        is_completed: newRunChecklist?.is_completed,
        checklist_status_indicators_id: newRunChecklist?.checklist_status_indicators_id || undefined,
        run_checklist_id: newRunChecklist?.run_checklist_id,
        run_id: newRunChecklist?.run_id
    })
}