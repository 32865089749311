import { GET_ORGANIZATION_QUERY } from '@/graphql/organization.gql';
import { useOrganizationStore } from '@/stores/organization.store';
import { Organizations } from '@/types/organization.type';
import { useQuery } from '@apollo/client';
import { useUserId } from '@nhost/nextjs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { LoadingSpinFullScreen } from '../utils/loading.component';

interface Props {
  children: React.ReactNode;
}

export function FetchOrganizationComponent({ children }: Props) {
  // Variables
  const router = useRouter();
  const userId = useUserId();

  // Store
  const [isLoading, setOrganization] = useOrganizationStore(
    s => [s.isLoading, s.setOrganization],
    shallow
  );

  // GraphQL
  const { loading, error, data } = useQuery<Organizations>(
    GET_ORGANIZATION_QUERY,
    {
      variables: {
        userId
      },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (isLoading && data) {
      // Get the orgId from URL
      let orgId;
      if (router?.pathname === '/projects' && router?.query?.orgId) {
        orgId = router?.query?.orgId as string;
      }

      setOrganization(data, orgId);
    }
  }, [isLoading, data, router, setOrganization]);

  if (loading || isLoading) {
    return <LoadingSpinFullScreen />;
  }

  if (data && data?.organization?.length == 0) {
    if (
      router.pathname != '/organization' &&
      !router.pathname.startsWith('/choose-projects/') &&
      !router.pathname.startsWith('/invite-members/')
    ) {
      router.push('/organization');
      return <LoadingSpinFullScreen />;
    }
  } else {
    if (
      router.pathname == '/organization' ||
      router.pathname.startsWith('/choose-projects/') ||
      router.pathname.startsWith('/invite-members/')
    ) {
      router.push('/projects');
      return <LoadingSpinFullScreen />;
    }
  }

  if (error) {
    throw error;
  }

  return <>{children}</>;
}
