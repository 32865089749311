import { useUserData } from '@nhost/nextjs';
import { useState } from 'react';
import CreateRunPopupComponent from '../../../runs/create-run-popup.component';
import { CLPrimaryButton } from '../../../ui-controls';

export default function CreateRunButtonComponent({
  checklistId,
  checklistTitle,
  checklistVersionId
}: {
  checklistId: string;
  checklistTitle: string;
  checklistVersionId: string | undefined;
}) {
  // Variables

  // Hooks
  const userData = useUserData();

  // States
  const [isRunPopupOpen, setIsRunPopupOpen] = useState<boolean>(false);

  return (
    <>
      <CLPrimaryButton
        className="whitespace-nowrap gap-2 min-h-[38px]"
        title="Run"
        onClick={() => {
          setIsRunPopupOpen(true);
        }}
      >
        <svg
          className="w-[12px]"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.491 15.8165C1.16427 16.0484 0.834076 16.0605 0.500432 15.8527C0.166811 15.6448 0 15.3284 0 14.9034V1.09659C0 0.671599 0.166811 0.355185 0.500432 0.147346C0.834076 -0.0605164 1.16427 -0.0484538 1.491 0.183534L11.5563 7.13706C11.8521 7.34492 12 7.63256 12 7.99999C12 8.36744 11.8521 8.65509 11.5563 8.86292L1.491 15.8165ZM1.52192 13.8262L10.0396 7.99999L1.52192 2.17379V13.8262Z"
            fill="#444444"
          />
        </svg>
        <span>Self Run</span>
      </CLPrimaryButton>

      <CreateRunPopupComponent
        checklistId={checklistId}
        checklistVersionId={checklistVersionId}
        runName={
          checklistTitle + ' - ' + (userData?.displayName || userData?.email)
        }
        isOpen={isRunPopupOpen}
        setIsOpen={setIsRunPopupOpen}
      />
    </>
  );
}
