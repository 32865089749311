import OpenAIConstants from '@/constants/open-ai-constants';
import { useAutoSaveStore } from '@/stores/builder/checklist-auto-save-tracker.store';
import { OpenAIResult } from '@/types/open-ai-type';
import axios from 'axios';
import { getProtectedAxiosInstance } from '../axios/Axios';
import { ChecklistTree } from '@/components/checklist/checklist-by-id.component';
import { Checklist } from '@/types/checklist.type';

export async function generateChecklistUsingAI(projectName: string, description: string | undefined, industry: string) {
    // Axios instance
    const protectedAxiosInstance = getProtectedAxiosInstance();

    try {
        const payload = {
            messages: [
                {
                    role: 'user',
                    content: `I want you act as a checklist generator for project/team space "${projectName}" in the industry "${industry}". 
                    Generate important top 10 checklists for the project "${projectName}". Keep it "short and direct". Don't include the project name. Also do not include punctuation and description.`
                }
            ],
            model: OpenAIConstants.MODEL,
            temperature: OpenAIConstants.TEMPERATURE,
            max_tokens: OpenAIConstants.MAX_TOKENS,
            top_p: OpenAIConstants.TOP_P,
            frequency_penalty: OpenAIConstants.FREQUENCY_PENALTY,
            presence_penalty: OpenAIConstants.PRESENCE_PENALTY
        };

        const result = await protectedAxiosInstance.post(
            '/generate-by-ai',
            payload
        );
        const openAIResult = result.data as OpenAIResult;

        return openAIResult;
    } catch (error) {
        // Show error to user
        console.error(error);

        throw error;
    }
}

export async function generateTasksUsingAI(checklistName: string, description: string | undefined,
    projectName: string, industry: string): Promise<OpenAIResult> {
    // Axios instance
    const protectedAxiosInstance = getProtectedAxiosInstance();

    return new Promise<OpenAIResult>((resolve, reject) => {
        try {
            const payload = {
                messages: [
                    {
                        role: 'user',
                        content: `I want you to act as a checklist generator for project/team space "${projectName}" in the industry "${industry}". 
                        I will provide the checklist name and your task is to generate a list of tasks for the given checklist.

                        The checklist name is "${checklistName}".`
                    }
                ],
                model: OpenAIConstants.MODEL,
                temperature: OpenAIConstants.TEMPERATURE,
                max_tokens: OpenAIConstants.MAX_TOKENS,
                top_p: OpenAIConstants.TOP_P,
                frequency_penalty: OpenAIConstants.FREQUENCY_PENALTY,
                presence_penalty: OpenAIConstants.PRESENCE_PENALTY
            };

            protectedAxiosInstance.post(
                '/generate-by-ai',
                payload
            ).then(result => {
                const openAIResult = result.data as OpenAIResult;

                resolve(openAIResult);
            });
        } catch (error) {
            // Show error to user
            console.error(error);

            reject(error);
        }
    })
}

export async function generateChecklistDescriptionUsingAI(checklistName: string): Promise<OpenAIResult> {
    // Axios instance
    const protectedAxiosInstance = getProtectedAxiosInstance();

    return new Promise<OpenAIResult>((resolve, reject) => {
        try {
            const payload = {
                messages: [
                    {
                        role: 'user',
                        content: `Give me a description of the ${checklistName} in 120 words`
                    }
                ],
                model: OpenAIConstants.MODEL,
                temperature: OpenAIConstants.TEMPERATURE,
                max_tokens: OpenAIConstants.MAX_TOKENS,
                top_p: OpenAIConstants.TOP_P,
                frequency_penalty: OpenAIConstants.FREQUENCY_PENALTY,
                presence_penalty: OpenAIConstants.PRESENCE_PENALTY
            };

            protectedAxiosInstance.post(
                '/generate-by-ai',
                payload
            ).then(result => {
                const openAIResult = result.data as OpenAIResult;

                resolve(openAIResult);
            }).catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.error(error);
                }
            });
        } catch (error) {
            // Show error to user
            console.error(error);

            reject(error);
        }
    })
}

export function updateAutoSaveAt() {
    const { updateAutoSaveAt } = useAutoSaveStore.getState();
    updateAutoSaveAt();
}

export function setFocusOnChecklistTaskTitle(checklistId: string) {
    if (checklistId) {
        setTimeout(() => {
            const element = document.getElementById(checklistId + '-form-overview-title-input');
            if (element && element?.focus) {
                setFocusOnSelectedChecklistFromTaskList(checklistId);

                element.focus();
            }
        }, 50);
    }
}

export function setFocusOnSelectedChecklistFromTaskList(checklistId: string) {
    if (checklistId) {
        setTimeout(() => {
            const element = document.getElementById(checklistId + '_task_list_button');
            if (element && element?.scrollIntoView) {
                element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        }, 100);
    }
}

function formChecklistTreeRecursive(
    parentNode: ChecklistTree,
    allChildChecklist: Checklist[]
) {
    const myChildrenChecklist = allChildChecklist.filter(
        checklist => checklist.parent_id === parentNode.checklist.id
    );

    myChildrenChecklist.sort((a, b) => {
        return (a.order_number as number) - (b.order_number as number);
    });

    parentNode.children = myChildrenChecklist.map(checklist => {
        return {
            checklist: JSON.parse(JSON.stringify(checklist)),
            children: []
        };
    });

    parentNode.children.forEach(childNode => {
        formChecklistTreeRecursive(childNode, allChildChecklist);
    });
}

export function formChecklistTree(checklistObject: Checklist) {
    let rootNode: ChecklistTree | null = null;
    if (checklistObject?.id && checklistObject?.checklistsByChecklistId) {
        rootNode = {
            checklist: JSON.parse(JSON.stringify(checklistObject)),
            children: []
        };

        formChecklistTreeRecursive(rootNode, checklistObject?.checklistsByChecklistId);
    }

    return rootNode;
}
