import { ChecklistComponentMapping } from '@/types/chcklist-component.type';
import { Checklist } from '@/types/checklist.type';
import { RunComponents, RunComponentsKeys } from '@/types/run-components.type';
import { useEffect, useState } from 'react';

export default function RunInstructionsComponentsComponent({
  checklistDetails
}: {
  checklistDetails: Checklist;
}) {
  // Variables
  const [componentMappings, setComponentMappings] = useState<
    ChecklistComponentMapping[]
  >([]);

  useEffect(() => {
    if (checklistDetails?.checklist_component_mappings) {
      setComponentMappings(
        checklistDetails?.checklist_component_mappings?.length > 0
          ? checklistDetails.checklist_component_mappings
          : []
      );
    }
  }, [checklistDetails]);

  return (
    <>
      <div className="flex flex-col gap-4">
        {componentMappings.map((componentMapping, index) => (
          <div key={index}>
            {componentMapping?.component_type && (
              <RenderSingleInstructionComponent
                type={componentMapping?.component_type}
                componentMapping={componentMapping}
              />
            )}
          </div>
        ))}
      </div>

      {/* Line */}
      {componentMappings.length > 0 && (
        <>
          <hr className="h-px my-4 bg-gray-50 border-0 dark:bg-gray-300" />
        </>
      )}
    </>
  );
}

function RenderSingleInstructionComponent({
  type,
  componentMapping
}: {
  type: RunComponentsKeys;
  componentMapping: ChecklistComponentMapping;
}) {
  const MyComponent = type ? RunComponents[type] : '';

  if (!MyComponent) {
    return <></>;
  }

  return (
    <>
      <MyComponent componentMapping={componentMapping} />
    </>
  );
}
