import { Organization, Organizations } from '@/types/organization.type';
import { create } from 'zustand';

interface OrganizationsStore {
    organizations: Organization[];
    isLoading: boolean;
    selectedOrganization: Organization | undefined;
    organizationPermissions: { [key: string]: boolean };
    setOrganization: (organizations: Organizations, orgId?: string) => void;
    setSelectedOrganization: (id: string) => void;
    updateOrganization: (organization: Organization) => void;
    setOrganizationPermissions: () => void;
}


export const useOrganizationStore = create<OrganizationsStore>((set, get) => ({
    organizations: [],
    isLoading: true,
    selectedOrganization: undefined,
    organizationPermissions: {},
    setOrganization: (_organizations: Organizations, orgId?: string) => {
        if (_organizations?.organization?.length > 0) {
            set((state) => ({
                organizations: _organizations?.organization
            }))
        }

        let defaultOrganization: Organization | undefined;
        // Select organization by orgId
        if (orgId) {
            // Find default
            defaultOrganization = _organizations?.organization.find(organization => organization?.id === orgId);
        }

        // Find default
        if (!defaultOrganization) {
            defaultOrganization = _organizations?.organization.find(organization => organization?.org_users?.[0]?.is_default === true);

            // Select the first one
            if (!defaultOrganization && _organizations?.organization?.length > 0) {
                defaultOrganization = _organizations?.organization[0];
            }
        }

        // Set default organization
        if (defaultOrganization) {
            set((state) => ({
                selectedOrganization: defaultOrganization
            }))
        }

        // Set organization permissions
        get().setOrganizationPermissions();

        // Stop loading
        set((state) => ({
            isLoading: false
        }))
    },
    setSelectedOrganization: (id: string) => {
        // Find default
        const selectedOrganization = get().organizations.find(organization => organization?.id === id);

        if (selectedOrganization) {
            set((state) => ({
                selectedOrganization: selectedOrganization
            }))
        }

        // Set organization permissions
        get().setOrganizationPermissions();
    },
    updateOrganization: (organization: Organization) => {
        // Update the selected organization name
        set((state) => ({
            selectedOrganization: {
                ...state.selectedOrganization as Organization,
                "name": organization?.name,
                "website": organization?.website,
                "size": organization?.size,
                "industry": organization?.industry
            }
        }))

        // Update the name in the list
        set((state) => ({
            organizations: state.organizations.map((_organization: Organization) => {
                return (_organization && _organization?.id === state?.selectedOrganization?.id) ?
                    ({
                        ..._organization, organization: {
                            ..._organization,
                            name: state.selectedOrganization?.name,
                            website: state.selectedOrganization?.website,
                            size: state.selectedOrganization?.size,
                            industry: state.selectedOrganization?.industry
                        }
                    }) :
                    _organization
            })
        }))
    },
    setOrganizationPermissions: () => {
        const selectedOrganization = get().selectedOrganization;

        // Set organization permissions
        if (selectedOrganization?.org_users?.[0]?.custom_role?.custom_role_permissions &&
            selectedOrganization?.org_users?.[0]?.custom_role?.custom_role_permissions?.length > 0) {
            const customRolePermissions = selectedOrganization?.org_users?.[0]?.custom_role?.custom_role_permissions;
            const customRolePermissionsObject: { [key: string]: boolean } = {};

            customRolePermissions.forEach((permission) => {
                if (permission?.custom_permission_id) {
                    customRolePermissionsObject[permission?.custom_permission_id] = true;
                }
            })

            set((state) => ({
                organizationPermissions: customRolePermissionsObject
            }))
        } else {
            set((state) => ({
                organizationPermissions: {}
            }))
        }
    }
}));