import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { ChecklistTree } from '../checklist/checklist-by-id.component';

export function RunProgressComponent() {
  // Variables

  // States
  const [completedPercentage, setCompletedPercentage] = useState<number>(10);

  // Store
  const [runChecklistTree] = useRunChecklistStore(
    s => [s.runChecklistTree],
    shallow
  );

  useEffect(() => {
    if (runChecklistTree && runChecklistTree?.children) {
      calculateCompletedPercentage();
    }
  }, [runChecklistTree]);

  function verifyRunChecklistTaskCompletion(
    checklistTree: ChecklistTree,
    totalObject: {
      totalNumberOfTasks: number;
      numberOfCompletedTasks: number;
    }
  ) {
    if (
      checklistTree &&
      checklistTree?.children &&
      checklistTree?.children?.length > 0
    ) {
      checklistTree.children.forEach(child => {
        // Count the number of tasks
        totalObject.totalNumberOfTasks++;

        // Verify if the task is completed
        if (child.checklist.is_completed) {
          totalObject.numberOfCompletedTasks++;
        }
        verifyRunChecklistTaskCompletion(child, totalObject);
      });
    }
  }

  function calculateCompletedPercentage() {
    const totalObject = {
      totalNumberOfTasks: 0,
      numberOfCompletedTasks: 0
    };

    if (runChecklistTree && runChecklistTree?.children) {
      verifyRunChecklistTaskCompletion(runChecklistTree, totalObject);

      // Calculate the percentage
      if (
        totalObject.totalNumberOfTasks >= totalObject.numberOfCompletedTasks &&
        totalObject.totalNumberOfTasks > 0
      ) {
        const percentage =
          (totalObject.numberOfCompletedTasks /
            totalObject.totalNumberOfTasks) *
          100;
        setCompletedPercentage(percentage);
      }
    }
  }

  return (
    <>
      {/* Default background */}
      <div
        className="relative w-full h-1"
        style={{ background: 'rgba(var(--primary-color-rgb), 0.2)' }}
      >
        {/* Progress bar */}
        <div
          className="absolute top-0 h-full"
          style={{
            background: 'rgb(var(--primary-color-rgb))',
            width: completedPercentage ? `${completedPercentage}%` : '0%',
            transition: 'width 0.7s ease-in-out'
          }}
        ></div>
      </div>
    </>
  );
}
