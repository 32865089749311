import { create } from 'zustand';

interface AutoSaveState {
    checklistId: string;
    autoSavedAt: number;
    updatedAt: string;
    publishedAt: string;
    updateAutoSaveAt: () => void;
    reset: () => void;
    updateUpdatedAt: (updatedAt: string) => void;
    updatePublishedAt: (publishedAt: string) => void;
}

export const useAutoSaveStore = create<AutoSaveState>(set => ({
    checklistId: '',
    autoSavedAt: 0,
    updatedAt: '',
    publishedAt: '',
    updateAutoSaveAt: () => set({ autoSavedAt: new Date().getTime() }),
    reset: () => set({ checklistId: '', autoSavedAt: 0, updatedAt: '', publishedAt: '' }),
    updateUpdatedAt: (updatedAt: string) => set({ updatedAt }),
    updatePublishedAt: (publishedAt: string) => set({ publishedAt })
}));
