import CommonConstants from '@/constants/common-constants';
import { useAccessToken } from '@nhost/nextjs';
import axios from 'axios';
import { useEffect } from 'react';

class AxiosSingletonClass {
  private static instance: AxiosSingletonClass;

  private protectedAxiosInstance;
  private accessToken = '';

  private constructor() {
    this.protectedAxiosInstance = axios.create({
      baseURL: CommonConstants.API_PREFIX
    });

    // Add a request interceptor to add the Bearer token to the headers of all HTTP requests
    this.protectedAxiosInstance.interceptors.request.use(
      config => {
        if (this.accessToken) {
          const token = this.accessToken;
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
          }
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  public static getAxiosClassInstance(): AxiosSingletonClass {
    if (!AxiosSingletonClass.instance) {
      AxiosSingletonClass.instance = new AxiosSingletonClass();
    }

    return AxiosSingletonClass.instance;
  }

  public getProtectedAxiosInstance() {
    return this.protectedAxiosInstance;
  }

  public updateAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }
}

export function getProtectedAxiosInstance() {
  const axiosInstance = AxiosSingletonClass.getAxiosClassInstance();
  return axiosInstance.getProtectedAxiosInstance();
}

export default function ProtectedAxiosComponent() {
  const accessToken = useAccessToken();
  const axiosInstance = AxiosSingletonClass.getAxiosClassInstance();

  useEffect(() => {
    if (accessToken) {
      axiosInstance.updateAccessToken(accessToken);
    }
  }, [axiosInstance, accessToken]);

  return null;
}
