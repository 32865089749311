import { PublicRun } from '@/types/public-run.type';
import { Run } from '@/types/run.type';
import { create } from 'zustand';

interface ResponsesRowSelectionState {
    selectedRun: Run | PublicRun | undefined;
    isPublicRun: boolean;
    isAssignedRun: boolean;
    setSelectedRun: (run: Run | PublicRun, isPublicRun: boolean, isAssignedRun: boolean) => void;
    reset: () => void;
}

export const useAnalyticsRowSelectionStore = create<ResponsesRowSelectionState>((set, get) => ({
    selectedRun: undefined,
    isPublicRun: false,
    isAssignedRun: false,
    setSelectedRun: (run: Run | PublicRun, isPublicRun: boolean, isAssignedRun: boolean) => {
        set({ selectedRun: run, isPublicRun, isAssignedRun });
    },
    reset: () => {
        set((state) => ({ selectedRun: undefined, isPublicRun: false, isAssignedRun: false }))
    }
}));
