import { gql } from "@apollo/client";

export const GET_MY_RUNS_QUERY = gql`
    query GetRuns($userId: uuid!, $orgId: uuid!) {
        runs_assignment(
          order_by: {created_at: desc}, 
          where: {user_id: {_eq: $userId}, 
          _and: {created_by: {_eq: $userId}}, 
          run: {checklist: 
            {
              project: {org_id: {_eq: $orgId}}, 
              is_deleted: {_is_null: true}
            }}}) {
            run {
                id
                name
                created_at
                due_at
                user {
                    displayName
                    email
                }
                run_checklists (where: {parent_id: {_is_null: true}}){
                  id
                  title
                  is_completed
                  completed_by
                  completed_at
                  updated_by
                  updated_at
                }
            }
        }
    }  
`;

export const GET_ASSIGN_TO_ME_RUNS_QUERY = gql`
  query GetRuns($userId: uuid!, $orgId: uuid!) {
    runs(where: {runs_assignments: {user_id: {_eq: $userId}}, checklist: {project: {org_id: {_eq: $orgId}, is_deleted: {_is_null: true}}, is_deleted: {_is_null: true}}, is_deleted: {_is_null: true}, checklist_version_id: {_is_null: false}}, order_by: {created_at: desc}) {
      id
      name
      created_at
      created_by
      due_at
      checklist_id
      checklist_version_id
      user {
        displayName
        email
      }
      run_checklist_id
      run_checklist {
        id
        title
        is_completed
        completed_by
        completed_at
        updated_by
        updated_at
      }
      checklist {
        project_id
      }
    }
  }
`;

export const GET_ASSIGN_TO_ME_RUNS_COMPLETED_STATUS_QUERY = gql`
  query GetRunsCompletedTasks($userId: uuid!, $orgId: uuid!) {
    runs(where: {runs_assignments: {user_id: {_eq: $userId}}, checklist: {project: {org_id: {_eq: $orgId}, is_deleted: {_is_null: true}}, is_deleted: {_is_null: true}}, is_deleted: {_is_null: true}}) {
      id
      completed_tasks: run_checklists_aggregate(where: {parent_id: {_is_null: false}, _and: {is_completed: {_eq: true}}}) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_RUN_BY_ID_QUERY = gql`
    query GetRunById($id: uuid!) {        
        runs(where: {id: {_eq: $id}}) {
            id
            name
            due_at
            runs_assignments {
              id
              userByUserId {
                id
                displayName
                email
              }
            }
        }  
    }
`;

export const GET_RUN_BY_ID_WITH_CHECKLIST_VERSION_QUERY = gql`
  query GetRunById($id: uuid!) {
    runs(where: {id: {_eq: $id}, checklist: {is_deleted: {_is_null: true}, _and: {project: {is_deleted: {_is_null: true}}}}}) {
      id
      name
      checklist_version_id
      run_checklist_id
      created_by
      checklist_version {
        checklistByVersionChecklistId {
          id
          title
          description
          task_id
          parent_id
          order_number
          checklistsByChecklistId {
            id
            title
            description
            task_id
            parent_id
            order_number
          }
          checklist_status_indicators {
            id
            name
            order_number
          }
        }
      }
      run_checklists {
        id
        task_id
        is_completed
        run_checklist_id
        run_id
        checklist_status_indicators_id
      }
    }
  }
`;

export const CREATE_RUN_AND_ASSIGN_MUTATION = gql`
    mutation CreateRunAndAssignMutation($runs: [runs_insert_input!]!, $runsAssignment: [runs_assignment_insert_input!]!) {
        insert_runs(
          objects: $runs
        ) {
          affected_rows
        }
    
        insert_runs_assignment(
          objects: $runsAssignment
        ) {
          affected_rows
        }
    }
`;

export const ASSIGN_RUN_MUTATION = gql`
    mutation AssignRunMutation($runId: uuid!, $userId: uuid!){
        insert_runs_assignment_one(object: {
            run_id: $runId, 
            user_id: $userId
        }) {
            id
            run_id
        }
    }
`;

export const CREATE_RUN_AND_START_RUN_MUTATION = gql`
    mutation CreateRunAndStartRunMutation(
        $runs: [runs_insert_input!]!, 
        $runsAssignment: [runs_assignment_insert_input!]!, 
        $runChecklist: [run_checklist_insert_input!]!,
        $runId: uuid!, 
        $runChecklistId: uuid!) {

        insert_runs(
          objects: $runs
        ) {
          affected_rows
        }
    
        insert_runs_assignment(
          objects: $runsAssignment
        ) {
          affected_rows
        }

        insert_run_checklist(
          objects: $runChecklist
        ) {
          affected_rows
        }

        update_runs_by_pk (
            pk_columns: {
              id: $runId
            }
            _set: {
              run_checklist_id: $runChecklistId
            }
          ) {
          id
        }
    }
`;

export const CREATE_RUN_CHECKLIST_MULTIPLE_MUTATION = gql`
    mutation CreateMultipleRunChecklistMutation($checklist: [run_checklist_insert_input!]!, $runId: uuid!, $runChecklistId: uuid!){
        insert_run_checklist(
            objects: $checklist
          ) {
            affected_rows
          }

        update_runs_by_pk (
          pk_columns: {id: $runId}
          _set: {
            run_checklist_id: $runChecklistId
          }
        ) {
          id
        }
    }
`;

export const INSERT_RUN_CHECKLIST_MUTATION = gql`
  mutation InsertRunChecklistMutation($checklist: [run_checklist_insert_input!]!){
    insert_run_checklist(
        objects: $checklist
      ) {
        affected_rows
      }
  }
`;

export const UPDATE_RUN_CHECKLIST_STATUS_MULTIPLE_MUTATION = gql`
  mutation updateRunChecklistStatusMutation($runChecklist: [run_checklist_insert_input!]!) {
    insert_run_checklist(
      objects: $runChecklist,
      on_conflict: {
        constraint: checklist_tasks_pkey,
        update_columns: [
          is_completed
          checklist_status_indicators_id
          completed_by
          completed_at
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_RUNS_AND_ASSIGNMENT_MUTATION = gql`
  mutation UpdateRunsMutation($runId: uuid!, $set: runs_set_input!, $runsAssignment: [runs_assignment_insert_input!]!) {
    update_runs_by_pk (
      pk_columns: {id: $runId}
      _set: $set
    ) {
      id
      name
      due_at
    }

    insert_runs_assignment(
        objects: $runsAssignment,
        on_conflict: {
          constraint: runs_assignment_run_id_user_id_key,
          update_columns: []
        }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_RUNS_ASSIGNMENT_MUTATION = gql`
  mutation DeleteRunsAssignmentByIdMutation($id: uuid!) {
    delete_runs_assignment_by_pk (
      id: $id
    ) {
      id
    }
  }
`;

export const DELETE_RUN_BY_ID_MUTATION = gql`
  mutation DeleteRunByIdMutation($runId: uuid!, $set: runs_set_input!) {
    update_runs_by_pk (
      pk_columns: {id: $runId}
      _set: $set
    ) {
      id
    }
  }
`;

export const GET_NOT_COMPLETED_RUNS_COUNT_QUERY = gql`
  query GetNotCompletedRunsCountQuery($userId: uuid!, $orgId: uuid!) {
    runs_aggregate(where: {runs_assignments: {user_id: {_eq: $userId}}, checklist: {project: {org_id: {_eq: $orgId}, is_deleted: {_is_null: true}}, is_deleted: {_is_null: true}}, is_deleted: {_is_null: true}, _or: [{run_checklist_id: {_is_null: true}}, {run_checklist: {_or: [{is_completed: {_eq: false}}, {is_completed: {_is_null: true}}]}}], checklist_version_id: {_is_null: false}}) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_RUN_CHECKLIST_DETAILS_QUERY = gql`
  query getChecklistDetailsById($checklistId: uuid!) {
    checklist(where: {id: {_eq: $checklistId}}) {
      id
      description
      checklist_component_mappings(order_by: {order_number: asc}) {
        order_number
        component_type
        component_text {
          content
        }
        component_image_group {
          name
          component_images {
            id
            url
            alt_text
          }
        }
        component_file_group {
          name
          component_files {
            id
            url
            org_file {
              file {
                name
                mimeType
                size
              }
            }
          }
        }
        component_embed {
          url
        }
      }
    }
  }
`;

export const GET_RUN_CHECKLIST_INSTRUCTION_BY_RUN_ID_QUERY = gql`
  query GetRunChecklistInstructionByRunId($id: uuid!) {
    runs(where: {id: {_eq: $id}}) {
      id
      checklist_version {
        checklistByVersionChecklistId {
          id
          checklistsByChecklistId {
            id
            checklist_component_mappings {
              id
            }
          }
          checklist_component_mappings {
            id
          }
        }
      }
    }
  }
`;

export const GetRunsByChecklistId = gql`
  query getRunsByChecklistId($id: uuid!) {
    runs(where: {checklist_id: {_eq: $id}}, order_by: {created_at: desc}) {
      id
      name
      created_at
      user {
        displayName
        email
      }
      run_checklist {
        is_completed
        updated_at
        completed_at
      }
    }
  }
`;

export const GetRunWithRunChecklistByRunId = gql`
  query getRunWithRunChecklistByRunId($id: uuid!) {
    runs(where: {id: {_eq: $id}}) {
      id
      name
      checklist_id
      checklist_version_id
      run_checklist_id
      checklist_version {
        checklistByVersionChecklistId {
          id
          title
          description
          task_id
          parent_id
          order_number
          checklistsByChecklistId {
            id
            title
            description
            task_id
            parent_id
            order_number
          }
          checklist_status_indicators {
            id
            name
            order_number
          }
        }
      }
      run_checklists {
        id
        task_id
        is_completed
        checklist_status_indicators_id
      }
    }
  }
`;