import { CLCustomButton, CLCustomInput } from '@/components/ui-controls';
import CLButton from '@/components/ui-controls/default-ui-controls/button';
import { CLLabel } from '@/components/ui-controls/default-ui-controls/label';
import {
  copyToClipboard,
  getChecklistCleanURL,
  openInNewTab
} from '@/services/utils/utils.service';
import { useChecklistStore } from '@/stores/builder/checklist.store';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import AssignChecklistButtonComponent from './assign-checklist-button.component';
import CreateRunButtonComponent from './create-run-button.component';
import CLToggle from '@/components/ui-controls/custom-ui-controls/cl-toggle-button';
import CLTooltip from '@/components/utils/tooltip';

interface PopupProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ShareChecklistPopupComponent({
  isOpen,
  setIsOpen
}: PopupProps) {
  // Variables
  const router = useRouter();
  const checklistId = router.query.checklistId;

  // States
  const [url, setUrl] = useState<string>('');
  const [anonymousUrl, setAnonymousUrl] = useState<string>('');

  // Store
  const [checklist] = useChecklistStore(state => [state.checklist], shallow);

  // GraphQL

  useEffect(() => {
    if (checklistId) {
      const protocol = window?.location?.hostname === 'localhost' ? 'http://' : 'https://';
      const host = window?.location?.host || 'app.checklist.gg';
      const path = getChecklistCleanURL(checklistId as string, checklist?.title || '');
      const query = '?anonymous=true';

      setUrl(`${protocol}${host}/${path}`);

      setAnonymousUrl(`${protocol}${host}/${path}${query}`)
    }
  }, [checklistId, checklist]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <>
      <div className="fixed w-[95%] sm:w-[400px] top-16 right-[2.5%] sm:right-4 p-4 bg-white border-2 rounded-lg z-10">
        {/* Header */}
        <div className="flex justify-end">
          <CLButton
            className="p-2"
            tabIndex={-1}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L8 6.58579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L9.41421 8L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8 9.41421L1.70711 15.7071C1.31658 16.0976 0.683417 16.0976 0.292893 15.7071C-0.0976311 15.3166 -0.0976311 14.6834 0.292893 14.2929L6.58579 8L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                fill="black"
                fillOpacity="0.5"
              />
            </svg>
          </CLButton>
        </div>

        {/* Body */}
        <div>

          {/* Public Run */}
          <div className="flex flex-col gap-2">
            <div className="flex gap-1">
              <CLLabel label="Public Run" />
              <div className='mt-1'>
                <CLTooltip content="By default asks for name and email id.">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M7.00015 0C3.13443 0 0.0015564 3.13336 0 6.99939C0.0015564 10.8656 3.13443 13.9981 7.00015 14C10.8662 13.9981 13.9988 10.8656 14 6.99939C13.9988 3.13336 10.8662 0 7.00015 0ZM7.00015 12.8257C3.78311 12.8206 1.18005 10.2167 1.17383 6.99939C1.1799 3.78204 3.78311 1.17834 7.00015 1.17261C10.2169 1.17834 12.8207 3.78204 12.8256 6.99939C12.8207 10.2167 10.2169 12.8206 7.00015 12.8257ZM6.99927 4.87048C7.50403 4.87048 7.91812 4.45764 7.91812 3.9519C7.91812 3.44684 7.50403 3.03381 6.99927 3.03381C6.49387 3.03381 6.08008 3.44684 6.08008 3.9519C6.08008 4.45776 6.49387 4.87048 6.99927 4.87048ZM6.17279 10.9659H7.8374L7.83755 5.67438H6.17279V10.9659Z"
                      fill="#808080" />
                  </svg>
                </CLTooltip>
              </div>
            </div>


            <div className="flex">
              <CLCustomInput
                type="text"
                className="w-full border-r-0 rounded-l-lg"
                value={url}
                disabled
              />

              {/* Open in new tab */}
              <CLCustomButton
                className="flex justify-center items-center px-4 py-2 border-[1px] border-black/10 border-r-0"
                onClick={() => {
                  openInNewTab(url);
                }}
              >
                <svg
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ width: '16px' }}
                >
                  <path
                    d="M1.98235 14.5C1.57059 14.5 1.22059 14.3559 0.932353 14.0676C0.644118 13.7794 0.5 13.4294 0.5 13.0176V1.98235C0.5 1.57059 0.644118 1.22059 0.932353 0.932353C1.22059 0.644118 1.57059 0.5 1.98235 0.5H6.57353C6.73824 0.5 6.88235 0.558196 7.00588 0.674588C7.12941 0.791529 7.19118 0.939216 7.19118 1.11765C7.19118 1.29608 7.12941 1.44349 7.00588 1.55988C6.88235 1.67682 6.73824 1.73529 6.57353 1.73529H1.98235C1.92745 1.73529 1.87255 1.76275 1.81765 1.81765C1.76275 1.87255 1.73529 1.92745 1.73529 1.98235V13.0176C1.73529 13.0725 1.76275 13.1275 1.81765 13.1824C1.87255 13.2373 1.92745 13.2647 1.98235 13.2647H13.0176C13.0725 13.2647 13.1275 13.2373 13.1824 13.1824C13.2373 13.1275 13.2647 13.0725 13.2647 13.0176V8.42647C13.2647 8.26176 13.3229 8.11765 13.4393 7.99412C13.5562 7.87059 13.7039 7.80882 13.8824 7.80882C14.0608 7.80882 14.2085 7.87059 14.3254 7.99412C14.4418 8.11765 14.5 8.26176 14.5 8.42647V13.0176C14.5 13.4294 14.3559 13.7794 14.0676 14.0676C13.7794 14.3559 13.4294 14.5 13.0176 14.5H1.98235ZM5.19412 9.80588C5.07059 9.69608 5.00882 9.55525 5.00882 9.38341C5.00882 9.21212 5.07059 9.06471 5.19412 8.94118L12.4 1.73529H9.76471C9.58627 1.73529 9.43886 1.67682 9.32247 1.55988C9.20553 1.44349 9.14706 1.29608 9.14706 1.11765C9.14706 0.939216 9.20553 0.791529 9.32247 0.674588C9.43886 0.558196 9.58627 0.5 9.76471 0.5H13.7588C13.9647 0.5 14.1398 0.571922 14.2842 0.715765C14.4281 0.860157 14.5 1.03529 14.5 1.24118V5.23529C14.5 5.41373 14.4418 5.56114 14.3254 5.67753C14.2085 5.79447 14.0608 5.85294 13.8824 5.85294C13.7039 5.85294 13.5562 5.79447 13.4393 5.67753C13.3229 5.56114 13.2647 5.41373 13.2647 5.23529V2.6L6.03824 9.82647C5.92843 9.95 5.79118 10.0118 5.62647 10.0118C5.46177 10.0118 5.31765 9.94314 5.19412 9.80588Z"
                    fill="#171717"
                  ></path>
                </svg>
              </CLCustomButton>

              {/* Copy url */}
              <CLCustomButton
                className="flex justify-center items-center px-4 py-2 border-[1px] border-black/10 rounded-r-lg"
                onClick={() => {
                  copyToClipboard(url);
                }}
              >
                <svg
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ width: '14px' }}
                >
                  <path
                    d="M1.62581 16.7097C1.17419 16.7097 0.790322 16.5516 0.474193 16.2355C0.158064 15.9194 0 15.5355 0 15.0839V3.6129H1.35484V15.0839C1.35484 15.1591 1.38103 15.223 1.43342 15.2754C1.48641 15.3283 1.55054 15.3548 1.62581 15.3548H10.3871V16.7097H1.62581ZM4.7871 13.5484C4.33548 13.5484 3.95161 13.3903 3.63548 13.0742C3.31935 12.7581 3.16129 12.3742 3.16129 11.9226V1.64839C3.16129 1.18172 3.31935 0.790323 3.63548 0.474194C3.95161 0.158065 4.33548 0 4.7871 0H12.3516C12.8183 0 13.2097 0.158065 13.5258 0.474194C13.8419 0.790323 14 1.18172 14 1.64839V11.9226C14 12.3742 13.8419 12.7581 13.5258 13.0742C13.2097 13.3903 12.8183 13.5484 12.3516 13.5484H4.7871ZM4.7871 12.1935H12.3516C12.4269 12.1935 12.4946 12.1671 12.5548 12.1141C12.6151 12.0617 12.6452 11.9978 12.6452 11.9226V1.64839C12.6452 1.57312 12.6151 1.50538 12.5548 1.44516C12.4946 1.38495 12.4269 1.35484 12.3516 1.35484H4.7871C4.71183 1.35484 4.648 1.38495 4.59561 1.44516C4.54262 1.50538 4.51613 1.57312 4.51613 1.64839V11.9226C4.51613 11.9978 4.54262 12.0617 4.59561 12.1141C4.648 12.1671 4.71183 12.1935 4.7871 12.1935ZM4.51613 12.1935V1.35484V12.1935Z"
                    fill="#171717"
                  ></path>
                </svg>
              </CLCustomButton>
            </div>
          </div>

          {/* Anonymous Run */}
          <div className="flex flex-col gap-2 mt-4">
            <div className="flex gap-1">
              <CLLabel label="Anonymous Run" />
              <div className='mt-1'>
                <CLTooltip content="Name and email id won't be asked.">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M7.00015 0C3.13443 0 0.0015564 3.13336 0 6.99939C0.0015564 10.8656 3.13443 13.9981 7.00015 14C10.8662 13.9981 13.9988 10.8656 14 6.99939C13.9988 3.13336 10.8662 0 7.00015 0ZM7.00015 12.8257C3.78311 12.8206 1.18005 10.2167 1.17383 6.99939C1.1799 3.78204 3.78311 1.17834 7.00015 1.17261C10.2169 1.17834 12.8207 3.78204 12.8256 6.99939C12.8207 10.2167 10.2169 12.8206 7.00015 12.8257ZM6.99927 4.87048C7.50403 4.87048 7.91812 4.45764 7.91812 3.9519C7.91812 3.44684 7.50403 3.03381 6.99927 3.03381C6.49387 3.03381 6.08008 3.44684 6.08008 3.9519C6.08008 4.45776 6.49387 4.87048 6.99927 4.87048ZM6.17279 10.9659H7.8374L7.83755 5.67438H6.17279V10.9659Z"
                      fill="#808080" />
                  </svg>
                </CLTooltip>
              </div>
            </div>
            <div className="flex">
              <CLCustomInput
                type="text"
                className="w-full border-r-0 rounded-l-lg"
                value={anonymousUrl}
                disabled
              />

              {/* Open in new tab */}
              <CLCustomButton
                className="flex justify-center items-center px-4 py-2 border-[1px] border-black/10 border-r-0"
                onClick={() => {
                  openInNewTab(anonymousUrl);
                }}
              >
                <svg
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ width: '16px' }}
                >
                  <path
                    d="M1.98235 14.5C1.57059 14.5 1.22059 14.3559 0.932353 14.0676C0.644118 13.7794 0.5 13.4294 0.5 13.0176V1.98235C0.5 1.57059 0.644118 1.22059 0.932353 0.932353C1.22059 0.644118 1.57059 0.5 1.98235 0.5H6.57353C6.73824 0.5 6.88235 0.558196 7.00588 0.674588C7.12941 0.791529 7.19118 0.939216 7.19118 1.11765C7.19118 1.29608 7.12941 1.44349 7.00588 1.55988C6.88235 1.67682 6.73824 1.73529 6.57353 1.73529H1.98235C1.92745 1.73529 1.87255 1.76275 1.81765 1.81765C1.76275 1.87255 1.73529 1.92745 1.73529 1.98235V13.0176C1.73529 13.0725 1.76275 13.1275 1.81765 13.1824C1.87255 13.2373 1.92745 13.2647 1.98235 13.2647H13.0176C13.0725 13.2647 13.1275 13.2373 13.1824 13.1824C13.2373 13.1275 13.2647 13.0725 13.2647 13.0176V8.42647C13.2647 8.26176 13.3229 8.11765 13.4393 7.99412C13.5562 7.87059 13.7039 7.80882 13.8824 7.80882C14.0608 7.80882 14.2085 7.87059 14.3254 7.99412C14.4418 8.11765 14.5 8.26176 14.5 8.42647V13.0176C14.5 13.4294 14.3559 13.7794 14.0676 14.0676C13.7794 14.3559 13.4294 14.5 13.0176 14.5H1.98235ZM5.19412 9.80588C5.07059 9.69608 5.00882 9.55525 5.00882 9.38341C5.00882 9.21212 5.07059 9.06471 5.19412 8.94118L12.4 1.73529H9.76471C9.58627 1.73529 9.43886 1.67682 9.32247 1.55988C9.20553 1.44349 9.14706 1.29608 9.14706 1.11765C9.14706 0.939216 9.20553 0.791529 9.32247 0.674588C9.43886 0.558196 9.58627 0.5 9.76471 0.5H13.7588C13.9647 0.5 14.1398 0.571922 14.2842 0.715765C14.4281 0.860157 14.5 1.03529 14.5 1.24118V5.23529C14.5 5.41373 14.4418 5.56114 14.3254 5.67753C14.2085 5.79447 14.0608 5.85294 13.8824 5.85294C13.7039 5.85294 13.5562 5.79447 13.4393 5.67753C13.3229 5.56114 13.2647 5.41373 13.2647 5.23529V2.6L6.03824 9.82647C5.92843 9.95 5.79118 10.0118 5.62647 10.0118C5.46177 10.0118 5.31765 9.94314 5.19412 9.80588Z"
                    fill="#171717"
                  ></path>
                </svg>
              </CLCustomButton>

              {/* Copy url */}
              <CLCustomButton
                className="flex justify-center items-center px-4 py-2 border-[1px] border-black/10 rounded-r-lg"
                onClick={() => {
                  copyToClipboard(anonymousUrl);
                }}
              >
                <svg
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ width: '14px' }}
                >
                  <path
                    d="M1.62581 16.7097C1.17419 16.7097 0.790322 16.5516 0.474193 16.2355C0.158064 15.9194 0 15.5355 0 15.0839V3.6129H1.35484V15.0839C1.35484 15.1591 1.38103 15.223 1.43342 15.2754C1.48641 15.3283 1.55054 15.3548 1.62581 15.3548H10.3871V16.7097H1.62581ZM4.7871 13.5484C4.33548 13.5484 3.95161 13.3903 3.63548 13.0742C3.31935 12.7581 3.16129 12.3742 3.16129 11.9226V1.64839C3.16129 1.18172 3.31935 0.790323 3.63548 0.474194C3.95161 0.158065 4.33548 0 4.7871 0H12.3516C12.8183 0 13.2097 0.158065 13.5258 0.474194C13.8419 0.790323 14 1.18172 14 1.64839V11.9226C14 12.3742 13.8419 12.7581 13.5258 13.0742C13.2097 13.3903 12.8183 13.5484 12.3516 13.5484H4.7871ZM4.7871 12.1935H12.3516C12.4269 12.1935 12.4946 12.1671 12.5548 12.1141C12.6151 12.0617 12.6452 11.9978 12.6452 11.9226V1.64839C12.6452 1.57312 12.6151 1.50538 12.5548 1.44516C12.4946 1.38495 12.4269 1.35484 12.3516 1.35484H4.7871C4.71183 1.35484 4.648 1.38495 4.59561 1.44516C4.54262 1.50538 4.51613 1.57312 4.51613 1.64839V11.9226C4.51613 11.9978 4.54262 12.0617 4.59561 12.1141C4.648 12.1671 4.71183 12.1935 4.7871 12.1935ZM4.51613 12.1935V1.35484V12.1935Z"
                    fill="#171717"
                  ></path>
                </svg>
              </CLCustomButton>
            </div>
          </div>

          {/* Draw line */}
          <hr className="h-px my-4 bg-gray-200 border-0" />

          {/* Run or Assign */}
          <div className="flex flex-col gap-2">
            <CLLabel label="Self Run or Assign" />

            {/* Buttons */}
            <div className="flex gap-2">
              {checklist?.id && checklist?.checklist_version_id && (
                <CreateRunButtonComponent
                  checklistId={checklist?.id}
                  checklistTitle={checklist?.title || 'Untitled'}
                  checklistVersionId={checklist?.checklist_version_id}
                />
              )}

              {checklist?.id &&
                checklist?.checklist_version_id &&
                checklist?.project_id && (
                  <AssignChecklistButtonComponent
                    projectId={checklist?.project_id}
                    checklistId={checklist?.id}
                    checklistTitle={checklist?.title || 'Untitled'}
                    checklistVersionId={checklist?.checklist_version_id}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
