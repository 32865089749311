import BuilderLayout from "@/layouts/builder-layout";
import AuthLayout from "../layouts/auth-layout";
import DashboardLayout from "../layouts/dashboard-layout";

export const MyLayouts = {
    Auth: AuthLayout,
    Dashboard: DashboardLayout,
    Builder: BuilderLayout
}

export type MyLayoutKeys = keyof typeof MyLayouts;