import RunEmbedComponent from "@/components/runs/components/run-embed-component.component";
import RunFileGroupComponent from "@/components/runs/components/run-file-group-component.component";
import RunImageGroupComponent from "@/components/runs/components/run-image-group-component.component";
import RunTextComponent from "@/components/runs/components/run-text-component.component";
import { ChecklistComponentMapping } from "./chcklist-component.type";

export const RunComponents = {
    text: RunTextComponent,
    image: RunImageGroupComponent,
    file: RunFileGroupComponent,
    embed: RunEmbedComponent
}

export type RunComponentsKeys = keyof typeof RunComponents;

export interface RunComponentCommonProps {
    componentMapping: ChecklistComponentMapping;
}