import { Project, ProjectInvite, ProjectUser } from "@/types/projects.type";
import { create } from 'zustand';

interface Permissions {
    [key: string]: boolean
}

interface ProjectPermissions {
    [key: string]: Permissions
}

interface ProjectsState {
    orgId: string;
    projects: Project[];
    isLoading: boolean;
    projectPermissions: ProjectPermissions;
    newProjectIds: { [key: string]: boolean };
    setProjects: (orgId: string, projects: Project[]) => void;
    addProject: (project: Project) => void;
    updateProject: (projectId: string, projectName: string) => void;
    deleteProject: (projectId: string) => string;
    resetProjects: () => void;
    setProjectUsers: (projectId: string, projectUsers: ProjectUser[], projectInvites: ProjectInvite[]) => void;
    getProjectUsers: (projectId: string) => { project_users: ProjectUser[], project_invites: ProjectInvite[] } | undefined;
    setProjectPermissions: () => void;
    setIsLoading: (status: boolean) => void;
}


export const useProjectsStore = create<ProjectsState>((set, get) => ({
    orgId: "",
    projects: [],
    isLoading: true,
    projectPermissions: {},
    newProjectIds: {},
    setProjects: (orgId: string, projects: Project[]) => {
        set(state => ({ orgId: orgId }));

        set(state => ({ projects: projects }));

        // Set project permissions
        get().setProjectPermissions();

        // Stop loading
        set((state) => ({
            isLoading: false
        }))
    },
    addProject: (project: Project) => {
        set(state => ({ projects: [...state.projects, ...[project]] }))

        // Newly added projectId's
        set(state => ({ newProjectIds: { ...state.newProjectIds, [project.id]: true } }))
    },
    updateProject: (projectId: string, projectName: string) => {
        set((state) => ({
            projects: state.projects.map((project: Project) => {
                return (project && project?.id === projectId) ?
                    ({ ...project, name: projectName }) :
                    project
            })
        }))
    },
    deleteProject: (projectId: string) => {
        set(state => ({ projects: state.projects?.filter(project => project.id != projectId) }))

        const currentProjects: Project[] = get().projects;
        if (currentProjects && currentProjects?.length > 0) {
            return currentProjects[0].id;
        } else {
            return '';
        }

    },
    resetProjects: () => {
        set(state => ({ projects: [] }));
    },
    setProjectUsers: (projectId: string, projectUsers: ProjectUser[], projectInvites: ProjectInvite[]) => {
        // Update the list of users to Project
        set((state) => ({
            projects: state.projects.map((_project: Project) => {
                return (_project && _project?.id === projectId) ?
                    ({ ..._project, project_users: projectUsers, project_invites: projectInvites }) :
                    _project
            })
        }))
    },
    getProjectUsers: (projectId: string) => {
        const project = get().projects?.find(project => project?.id === projectId);

        if (project && project?.project_users && project?.project_invites) {
            return {
                project_users: project?.project_users,
                project_invites: project?.project_invites
            };
        } else {
            return undefined;
        }
    },
    setProjectPermissions: () => {
        const projects = get().projects;

        if (projects && projects?.length > 0) {
            const _projectPermissions: ProjectPermissions = {};

            projects?.forEach(project => {
                _projectPermissions[project.id] = {};

                if (project?.project_users?.[0]?.custom_role?.custom_role_permissions &&
                    project?.project_users?.[0]?.custom_role?.custom_role_permissions?.length > 0) {
                    const customRolePermissions = project?.project_users?.[0]?.custom_role?.custom_role_permissions;
                    const customRolePermissionsObject: { [key: string]: boolean } = {};

                    customRolePermissions.forEach((permission) => {
                        if (permission?.custom_permission_id) {
                            customRolePermissionsObject[permission?.custom_permission_id] = true;
                        }
                    })

                    _projectPermissions[project.id] = customRolePermissionsObject;
                }
            })

            set((state) => ({
                projectPermissions: _projectPermissions
            }))
        }
        else {
            set((state) => ({
                projectPermissions: {}
            }))
        }

        // // Set organization permissions
        // if (selectedOrganization?.org_users?.[0]?.custom_role?.custom_role_permissions &&
        //     selectedOrganization?.org_users?.[0]?.custom_role?.custom_role_permissions?.length > 0) {
        //     const customRolePermissions = selectedOrganization?.org_users?.[0]?.custom_role?.custom_role_permissions;
        //     const customRolePermissionsObject: { [key: string]: boolean } = {};

        //     customRolePermissions.forEach((permission) => {
        //         if (permission?.custom_permission_id) {
        //             customRolePermissionsObject[permission?.custom_permission_id] = true;
        //         }
        //     })

        //     set((state) => ({
        //         organizationPermissions: customRolePermissionsObject
        //     }))
        // } else {
        //     set((state) => ({
        //         organizationPermissions: {}
        //     }))
        // }
    },
    setIsLoading: (status: boolean) => {
        set((state) => ({
            isLoading: status
        }))
    }
}));
