import { FetchOrganizationComponent } from '@/components/graphql-execution/fetch-organization.component';
import { FetchProjectComponent } from '@/components/graphql-execution/fetch-project.component';
import { LoadingSpinFullScreen } from '@/components/utils/loading.component';
import { useAuthenticationStatus } from '@nhost/nextjs';
import { useRouter } from 'next/router';

interface Props {
  children: React.ReactElement;
}

export default function SessionAuth({ children }: Props) {
  // Variables
  const router = useRouter();
  const { isLoading, isAuthenticated } = useAuthenticationStatus();

  if (isLoading) {
    return <LoadingSpinFullScreen />;
  }

  if (!isAuthenticated) {
    router.push('/login');
    return null;
  }

  return (
    <>
      {/* Fetch org details */}
      <FetchOrganizationComponent>
        {/* Fetch projects */}
        <FetchProjectComponent />

        {/* Render child screen */}
        {children}
      </FetchOrganizationComponent>
    </>
  );
}
