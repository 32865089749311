import { GET_ORGANIZATION_PROJECTS_QUERY } from '@/graphql/organization.gql';
import { useOrganizationStore } from '@/stores/organization.store';
import { useProjectsStore } from '@/stores/projects.store';
import { Projects } from '@/types/projects.type';
import { useQuery } from '@apollo/client';
import { useUserId } from '@nhost/nextjs';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

export function FetchProjectComponent() {
  // Variables
  const userId = useUserId();

  // Stores
  const [selectedOrganization] = useOrganizationStore(
    s => [s.selectedOrganization],
    shallow
  );
  const [isLoading, setIsLoading, orgId, setProjects] = useProjectsStore(
    s => [s.isLoading, s.setIsLoading, s.orgId, s.setProjects],
    shallow
  );

  // GraphQL
  const { data, loading, error } = useQuery<Projects>(
    GET_ORGANIZATION_PROJECTS_QUERY,
    {
      variables: { id: selectedOrganization?.id, userId },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    // Show loading
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (
      selectedOrganization?.id &&
      data?.projects &&
      (isLoading || orgId != selectedOrganization?.id)
    ) {
      setProjects(selectedOrganization?.id, data.projects);
    }
  }, [selectedOrganization, isLoading, data, setProjects, orgId]);

  return <></>;
}
