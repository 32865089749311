import { Checklist } from '@/types/checklist.type';
import { OrgGuestUser, PublicRun, PublicRunChecklist } from '@/types/public-run.type';
import { create } from 'zustand';

interface PublicRunStore {
    previewChecklist: Checklist | undefined;
    orgGuestUser: OrgGuestUser | undefined;
    checklist?: Checklist | undefined;
    publicRun?: PublicRun | undefined;
    publicRunChecklist?: PublicRunChecklist[] | undefined;
    publicRunAccessToken?: string | undefined;
    initialize: (orgGuestUser: OrgGuestUser, checklist: Checklist, publicRun: PublicRun, publicRunChecklist: PublicRunChecklist[], publicRunAccessToken: string) => void;
    setPreviewChecklist: (previewChecklist: Checklist) => void;
    reset: () => void;
}

export const usePublicRunStore = create<PublicRunStore>((set, get) => ({
    previewChecklist: undefined,
    orgGuestUser: undefined,
    checklist: undefined,
    publicRun: undefined,
    publicRunChecklist: undefined,
    publicRunAccessToken: undefined,
    setPreviewChecklist: (previewChecklist: Checklist) => {
        set({ previewChecklist });
    },
    initialize: (orgGuestUser, checklist, publicRun, publicRunChecklist, publicRunAccessToken) => {
        set(state => ({ orgGuestUser, checklist, publicRun, publicRunChecklist, publicRunAccessToken }));
    },
    reset: () => set({
        orgGuestUser: undefined,
        checklist: undefined,
        publicRun: undefined,
        publicRunChecklist: undefined
    })
}));
