import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function BuilderLayoutTabs() {
  // Variables
  const router = useRouter();
  const checklistId = router.query.checklistId;
  const tabs = [
    {
      id: 'design',
      title: 'Design',
      route: '/checklist/' + checklistId
    },
    {
      id: 'share',
      title: 'Share',
      route: '/share/' + checklistId
    },
    {
      id: 'responses',
      title: 'Analytics',
      route: '/responses/' + checklistId
    }
  ];

  // States
  const [activeTab, setActiveTab] = useState<string>('');

  useEffect(() => {
    // Set the tabId based on url
    if (router.pathname) {
      if (router.pathname.startsWith('/checklist/')) {
        setActiveTab('design');
      } else if (router.pathname.startsWith('/share/')) {
        setActiveTab('share');
      } else if (router.pathname.startsWith('/responses/')) {
        setActiveTab('responses');
      }
    }
  }, [router]);

  return (
    <>
      <div className="flex items-center gap-4 h-full">
        {tabs.map((tab, index) => (
          <Link
            key={tab.id}
            href={tab.route}
            className={
              'font-semibold flex items-center flex-nowrap text-center h-full border-b-2 ' +
              (activeTab == tab.id
                ? 'opacity-1 border-black'
                : 'opacity-60 border-transparent hover:border-black/70 hover:opacity-70')
            }
          >
            {index + 1 + '. ' + tab.title}
          </Link>
        ))}
      </div>
    </>
  );
}
