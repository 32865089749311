const ROLES_CONSTANTS = {
    "ORGANIZATION": {
        "OWNER": "5dd73c8d-d001-4814-800d-f93054ed34c5",
        "ADMIN": "b9133164-3678-4cf5-9834-955a68e355d0",
        "MEMBER": "cc0b37ac-4141-4343-8dfc-8d3ff4e276ec",
        "GUEST": "7626e85d-ca75-4fd1-9c05-30c5e1a4bb59",
    },
    "PROJECT": {
        "ADMIN": "ab6c5b1e-4d65-40be-9beb-a6b77840af39",
        "MEMBER": "286fe0d0-395f-43df-833d-6b6aed52181d",
        "GUEST": "e2690ce0-d6cd-4427-b3f4-2ad578b33f15"
    }
}

export default ROLES_CONSTANTS;