import { RunChecklistTree } from '@/components/runs/run-by-id.component';
import { Run } from '@/types/run.type';
import { ChecklistStatusIndicator } from '@/types/status-indicators.type';
import { create } from 'zustand';

interface RunChecklistState {
    runChecklistObject: Run | undefined;
    runChecklistTree: RunChecklistTree | undefined;
    statusIndicators: ChecklistStatusIndicator[];
    runChecklistInstructionsMappingObject: { [key: string]: boolean }
    isSaving: boolean;
    isPreview: boolean;
    isPublic: boolean;
    isStatusIndicatorDisabled: boolean;
    isSingleResponse: boolean;
    setRunChecklistObject: (runChecklistObject: Run) => void;
    setRunChecklistTree: (runChecklistTree: RunChecklistTree) => void;
    refreshRunChecklistTree: () => void;
    setRunChecklistInstructionsMappingObject: (runChecklistInsturctionsMappingObject: { [key: string]: boolean }) => void;
    setIsSaving: (isSaving: boolean) => void;
    reset: () => void;
    setIsPreview: (isPreview: boolean) => void;
    setIsPublic: (isPublic: boolean) => void;
    setIsStatusIndicatorDisabled: (isPublic: boolean) => void;
    setIsSingleResponse: (isPublic: boolean) => void;
}

export const useRunChecklistStore = create<RunChecklistState>((set, get) => ({
    runChecklistObject: undefined,
    runChecklistTree: undefined,
    statusIndicators: [],
    runChecklistInstructionsMappingObject: {},
    isSaving: false,
    isPreview: false,
    isPublic: false,
    isStatusIndicatorDisabled: false,
    isSingleResponse: false,

    setRunChecklistObject: (runChecklistObject: Run) => {
        set(state => ({ runChecklistObject: runChecklistObject }));

        if (runChecklistObject) {
            if (
                runChecklistObject?.checklist_version?.checklistByVersionChecklistId
                    ?.checklist_status_indicators &&
                runChecklistObject?.checklist_version?.checklistByVersionChecklistId
                    ?.checklist_status_indicators?.length > 0
            ) {
                set(state => ({ statusIndicators: runChecklistObject?.checklist_version?.checklistByVersionChecklistId?.checklist_status_indicators }));
            } else {
                set(state => ({
                    statusIndicators: [{
                        id: '',
                        name: 'Completed',
                        order_number: 0
                    }]
                }));

            }
        }
    },

    setRunChecklistTree: (runChecklistTree: RunChecklistTree) => {
        set(state => ({ runChecklistTree: runChecklistTree }));
    },

    refreshRunChecklistTree: () => {
        set(state => ({ runChecklistTree: state.runChecklistTree ? { ...state.runChecklistTree } : undefined }));
    },

    setIsSaving: (isSaving: boolean) => {
        set(state => ({ isSaving: isSaving }));
    },

    setRunChecklistInstructionsMappingObject: (runChecklistInsturctionsMappingObject: { [key: string]: boolean }) => {
        set(state => ({ runChecklistInstructionsMappingObject: { ...runChecklistInsturctionsMappingObject } }));
    },

    reset: () => {
        set({
            runChecklistObject: undefined,
            runChecklistTree: undefined,
            statusIndicators: [],
            runChecklistInstructionsMappingObject: {},
            isSaving: false,
            isPreview: false
        }
        )
    },

    setIsPreview: (isPreview: boolean) => {
        set(state => ({ isPreview }));
    },

    setIsPublic: (isPublic: boolean) => {
        set(state => ({ isPublic }));
    },

    setIsStatusIndicatorDisabled: (isStatusIndicatorDisabled: boolean) => {
        set(state => ({ isStatusIndicatorDisabled }));
    },

    setIsSingleResponse: (isSingleResponse: boolean) => {
        set(state => ({ isSingleResponse }));
    }
}));
