import { CLCustomButton } from '@/components/ui-controls';
import CLTooltip from '@/components/utils/tooltip';
import { useState } from 'react';
import ChecklistBuilderPreviewPopupComponent from './checklist-builder-preview-popup.component';

export default function ChecklistBuilderPreviewButtonComponent() {
  // Variables

  // States
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      {/* Icon */}
      <CLTooltip content="Preview" position="bottom">
        <CLCustomButton
          className="h-full flex justify-center items-center rounded-full px-4 py-2 shadow-lg hover:bg-black/10"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: '20px' }}
          >
            <path
              d="M0 10C0.868842 5.44889 5.01618 2 10 2C14.9838 2 19.1302 5.44889 20 10C19.1312 14.5511 14.9838 18 10 18C5.01618 18 0.869766 14.5511 0 10ZM10 14.4444C11.2257 14.4444 12.4012 13.9762 13.2679 13.1427C14.1346 12.3092 14.6215 11.1787 14.6215 10C14.6215 8.82126 14.1346 7.6908 13.2679 6.8573C12.4012 6.02381 11.2257 5.55556 10 5.55556C8.7743 5.55556 7.59881 6.02381 6.73211 6.8573C5.86541 7.6908 5.3785 8.82126 5.3785 10C5.3785 11.1787 5.86541 12.3092 6.73211 13.1427C7.59881 13.9762 8.7743 14.4444 10 14.4444ZM10 12.6667C9.26458 12.6667 8.55928 12.3857 8.03926 11.8856C7.51924 11.3855 7.2271 10.7072 7.2271 10C7.2271 9.29276 7.51924 8.61448 8.03926 8.11438C8.55928 7.61428 9.26458 7.33333 10 7.33333C10.7354 7.33333 11.4407 7.61428 11.9607 8.11438C12.4808 8.61448 12.7729 9.29276 12.7729 10C12.7729 10.7072 12.4808 11.3855 11.9607 11.8856C11.4407 12.3857 10.7354 12.6667 10 12.6667Z"
              fill="#171717"
            ></path>
          </svg>
        </CLCustomButton>
      </CLTooltip>

      {/* Popup */}
      <ChecklistBuilderPreviewPopupComponent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
}
