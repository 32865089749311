import { LoadingSpinBlackCustom } from '@/components/utils/loading.component';
import CommonConstants from '@/constants/common-constants';
import { GET_RUN_CHECKLIST_DETAILS_QUERY } from '@/graphql/runs.gql';
import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { Checklist } from '@/types/checklist.type';
import { useQuery } from '@apollo/client';
import axios, { AxiosError, CancelToken } from 'axios';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import RunInstructionsComponentsComponent from './run-instructions-components.component';

function RenderRunInstructionsComponent({
  checklistId
}: {
  checklistId: string;
}) {
  // Variables

  // States
  const [checklistDetails, setChecklistDetails] = useState<Checklist>();

  // GraphQL
  const { loading, data, error } = useQuery<{ checklist: Checklist[] }>(
    GET_RUN_CHECKLIST_DETAILS_QUERY,
    {
      variables: {
        checklistId
      },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (data?.checklist && data?.checklist?.length > 0) {
      setChecklistDetails(data?.checklist[0]);
    }
  }, [data]);

  if (loading) {
    return (
      <>
        <div className="flex flex-col gap-2 animate-pulse">
          <div className="h-12 bg-gray-200 rounded"></div>
          <div className="h-12 bg-gray-200 rounded"></div>
        </div>
      </>
    );
  }

  return (
    <>
      {checklistDetails?.id && (
        <div>
          {/* Description */}
          {checklistDetails?.description && (
            <>
              <div>{checklistDetails?.description}</div>

              {/* Line */}
              <hr className="h-px my-4 bg-gray-50 border-0 dark:bg-gray-300" />
            </>
          )}

          {/* List of components */}
          <RunInstructionsComponentsComponent
            checklistDetails={checklistDetails}
          />
        </div>
      )}
    </>
  );
}

function RenderPublicRunInstructionsComponent({
  checklistId
}: {
  checklistId: string;
}) {
  // Variables

  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checklistDetails, setChecklistDetails] = useState<Checklist>();

  useEffect(() => {
    const { token: cancelToken, cancel } = axios.CancelToken.source();

    fetchChecklistDetails(checklistId, cancelToken);

    return () => {
      cancel('Cancelled');
    };
  }, []);

  async function fetchChecklistDetails(
    checklistId: string,
    cancelToken: CancelToken
  ) {
    // Fetch all instruction mappings
    try {
      // Start fetching
      setIsLoading(true);

      const payload = {
        checklistId: checklistId
      };

      const response = await axios.post(
        CommonConstants.API_PREFIX + '/public/run/get-checklist-instructions',
        payload,
        {
          cancelToken: cancelToken
        }
      );

      if (
        response?.data?.data?.checklist &&
        response?.data?.data?.checklist?.length > 0
      ) {
        setChecklistDetails(response?.data?.data?.checklist[0]);
      }

      // Stop fetching
      setIsLoading(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error?.response?.data?.error?.message || error.message);
      } else {
        console.error('Something Went wrong. Please try again');
      }
    }
  }

  if (isLoading) {
    return (
      // <>
      //   <div className="bg-gray-100 p-2 w-full rounded-lg flex justify-center items-center min-h-[48px]">
      //     <LoadingSpinBlackCustom className="w-5 h-5"></LoadingSpinBlackCustom>
      //   </div>
      // </>

      <>
        <div className="flex flex-col gap-2 animate-pulse">
          <div className="h-12 bg-gray-200 rounded"></div>
          <div className="h-12 bg-gray-200 rounded"></div>
        </div>
      </>
    );
  }

  return (
    <>
      {checklistDetails?.id && (
        <div>
          {/* Description */}
          {checklistDetails?.description && (
            <>
              <div>{checklistDetails?.description}</div>

              {/* Line */}
              <hr className="h-px my-4 bg-gray-50 border-0 dark:bg-gray-300" />
            </>
          )}

          {/* List of components */}
          <RunInstructionsComponentsComponent
            checklistDetails={checklistDetails}
          />
        </div>
      )}
    </>
  );
}

export default function RunInstructionsComponent({
  selectedChecklistId
}: {
  selectedChecklistId: string;
}) {
  // Variables

  // States
  const [checklistId, setChecklistId] = useState<string>('');

  // Store
  const [isPublic, isPreview] = useRunChecklistStore(
    s => [s.isPublic, s.isPreview],
    shallow
  );

  useEffect(() => {
    if (checklistId !== selectedChecklistId) {
      setChecklistId(selectedChecklistId);
    }
  }, [selectedChecklistId, checklistId]);

  return (
    <>
      {checklistId &&
        (isPublic ? (
          <RenderPublicRunInstructionsComponent checklistId={checklistId} />
        ) : (
          <RenderRunInstructionsComponent checklistId={checklistId} />
        ))}
    </>
  );
}
