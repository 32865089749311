import {
  CLCustomButton,
  CLPrimaryCustomButton,
  CLPrimaryCustomTextarea
} from '@/components/ui-controls';
import CLTooltip from '@/components/utils/tooltip';
import {
  DELETE_RUN_CHECKLIST_COMMENT_BY_ID_MUTATION,
  GET_RUN_CHECKLIST_COMMENTS_BY_CHECKLIST_ID_QUERY,
  INSERT_RUN_CHECKLIST_COMMENT_MUTATION
} from '@/graphql/checklist-comments.gql';
import { downloadFile } from '@/services/axios/dowload-file';
import { convertISOToLocalDateString } from '@/services/utils/utils.service';
import { useOrganizationStore } from '@/stores/organization.store';
import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { OrgFile } from '@/types/chcklist-component.type';
import {
  RunChecklistComment,
  RunChecklistCommentFile,
  RunChecklistComments
} from '@/types/run-checklist-comments.type';
import { useMutation, useQuery } from '@apollo/client';
import { useFileUpload, useNhostClient, useUserData } from '@nhost/nextjs';
import { KeyboardEvent, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { shallow } from 'zustand/shallow';

interface RunCommentsComponentProps {
  runChecklistId: string;
}

interface uploadedFileProps {
  file: File;
  previewUrl?: string;
  publicUrl?: string;
}

export default function RunCommentsComponent({
  runChecklistId
}: RunCommentsComponentProps) {
  // Variables
  const nhost = useNhostClient();
  const { upload } = useFileUpload();
  const userData = useUserData();

  // States
  const [runChecklistComments, setRunChecklistComments] =
    useState<RunChecklistComment[]>();
  const [commentText, setCommentText] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<uploadedFileProps[]>([]);
  const [isCommentSaving, setIsCommentSaving] = useState<boolean>(false);

  // Store
  const [isPreview, isPublic, isSingleResponse] = useRunChecklistStore(
    s => [s.isPreview, s.isPublic, s.isSingleResponse],
    shallow
  );
  const [selectedOrganization] = useOrganizationStore(
    s => [s.selectedOrganization],
    shallow
  );

  // GraphQL
  const { loading, data, error } = useQuery<RunChecklistComments>(
    GET_RUN_CHECKLIST_COMMENTS_BY_CHECKLIST_ID_QUERY,
    {
      variables: {
        runChecklistId
      },
      fetchPolicy: 'no-cache'
    }
  );
  const [insertRunChecklistCommentMutation] = useMutation(
    INSERT_RUN_CHECKLIST_COMMENT_MUTATION
  );
  const [deleteRunChecklistCommentMutation] = useMutation(
    DELETE_RUN_CHECKLIST_COMMENT_BY_ID_MUTATION
  );

  useEffect(() => {
    () => {
      // Clear the data
      setRunChecklistComments([]);
      setCommentText('');
      setUploadedFiles([]);
      setIsCommentSaving(false);
    };
  }, []);

  useEffect(() => {
    if (data) {
      if (
        data?.run_checklist_comments &&
        data?.run_checklist_comments?.length > 0
      ) {
        setRunChecklistComments(data?.run_checklist_comments);
      } else {
        setRunChecklistComments([]);
      }
    }
  }, [data]);

  // Show loading till fetching comments
  if (loading) {
    return (
      <div className="flex gap-2 py-4 animate-pulse">
        {/* Profile image */}
        <div className="w-8 h-8 bg-gray-200 rounded-full"></div>

        {/* Comment block */}
        <div className="flex-1 flex flex-col gap-2">
          {/* Name */}
          <div className="w-2/4 h-4 bg-gray-200 rounded"></div>

          {/* Text */}
          <div className="h-20 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }

  async function addComment() {
    // Do a validation
    if (commentText || uploadedFiles.length > 0) {
      // Set the saving flag
      setIsCommentSaving(true);

      const newCommentId = uuidv4();
      const newRunChecklistComment: RunChecklistComment = {
        id: newCommentId,
        text: commentText || '',
        run_checklist_id: runChecklistId
      };

      // Collect uploaded files data
      const {
        orgFiles,
        runChecklistCommentFiles,
        runChecklistCommentFilesForUI
      } = await uploadFilesToFileStorage(newCommentId);

      // Insert the comment to database
      insertRunChecklistCommentMutation({
        variables: {
          runChecklistComments: [newRunChecklistComment],
          orgFiles: orgFiles,
          runChecklistCommentFiles: runChecklistCommentFiles
        }
      });

      // Add the comment to the list, it will refresh the UI
      if (runChecklistComments) {
        setRunChecklistComments([
          ...runChecklistComments,
          {
            ...newRunChecklistComment,
            ...{
              run_checklist_comment_files: runChecklistCommentFilesForUI
            },
            ...{
              created_at: new Date().toISOString(),
              user: {
                id: userData?.id,
                displayName: userData?.displayName,
                email: userData?.email,
                avatarUrl: userData?.avatarUrl
              }
            }
          }
        ]);
      }

      // Clear the comment text
      setCommentText('');
      setUploadedFiles([]);
      setIsCommentSaving(false);
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // if (e.key === 'Enter') {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   addComment();
    // }
  };

  function deleteComment(
    deleteComment: RunChecklistComment,
    commentIndex: number
  ) {
    if (deleteComment?.id) {
      // Delete the comment from database
      deleteRunChecklistCommentMutation({
        variables: {
          runChecklistCommentId: deleteComment?.id
        }
      });

      // Remove the comment from the list, it will refresh the UI
      if (runChecklistComments) {
        setRunChecklistComments([
          ...runChecklistComments.slice(0, commentIndex),
          ...runChecklistComments.slice(commentIndex + 1)
        ]);
      }
    }
  }

  // Upload File
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);

      // Iterate through each file and refresh the UI
      for (const file of files) {
        // Only for image add preview url
        uploadedFiles.push({
          file,
          previewUrl: file.type.includes('image')
            ? URL.createObjectURL(file)
            : ''
        });
      }
      setUploadedFiles([...uploadedFiles]);
    }
  };

  // Delete File
  const deleteUploadedFile = (index: number) => {
    uploadedFiles.splice(index, 1);
    setUploadedFiles([...uploadedFiles]);
  };

  // Upload the files to storage
  const uploadFilesToFileStorage = async (newCommentId: string) => {
    const orgFiles: OrgFile[] = [];
    const runChecklistCommentFiles: RunChecklistCommentFile[] = [];
    const runChecklistCommentFilesForUI: RunChecklistCommentFile[] = [];

    // Iterate through each file and upload
    if (uploadedFiles && uploadedFiles.length > 0) {
      for (const uploadedFile of uploadedFiles) {
        if (uploadedFile.file) {
          const uploadedFileInfo = await upload({ file: uploadedFile.file });

          if (uploadedFileInfo.id) {
            const publicUrl = await nhost.storage.getPublicUrl({
              fileId: uploadedFileInfo.id
            });

            // Insert a file component
            const orgFileId = uuidv4();
            const runChecklistCommentFileId = uuidv4();
            const orgFile = {
              id: orgFileId,
              org_id: selectedOrganization?.id,
              file_id: uploadedFileInfo.id
            };
            orgFiles.push(orgFile);
            const runChecklistCommentFile = {
              id: runChecklistCommentFileId,
              url: publicUrl,
              run_checklist_comment_id: newCommentId,
              org_file_id: orgFileId
            };
            runChecklistCommentFiles.push(runChecklistCommentFile);

            // This is just to show the file in UI
            runChecklistCommentFilesForUI.push({
              ...runChecklistCommentFile,
              org_file: {
                ...orgFile,
                ...{
                  file: {
                    id: uploadedFileInfo.id,
                    name: uploadedFile.file?.name,
                    mimeType: uploadedFile.file?.type
                  }
                }
              }
            });
          }
        }
      }
    }

    return {
      orgFiles,
      runChecklistCommentFiles,
      runChecklistCommentFilesForUI
    };
  };

  return (
    <>
      {/* List of comments */}
      <div className="flex flex-col gap-4 mb-4">
        {runChecklistComments?.map((comment, commentIndex) => {
          return (
            <div key={comment.id} className="group flex gap-2">
              {/* Each comment */}

              {/* Profile image */}
              <div className="w-8 h-8 cl_primary_background rounded-full text-lg text-gray-900 flex items-center justify-center uppercase">
                {/* Profile image */}
                {/* Icon */}
                {comment?.user?.avatarUrl && (
                  <>
                    <img
                      className="w-8 h-8 rounded-full mx-auto bg-slate-300"
                      src={comment?.user?.avatarUrl}
                      alt=""
                    />
                  </>
                )}

                {/* Display name */}
                {!comment?.user?.avatarUrl && comment?.user?.displayName && (
                  <>{comment.user?.displayName?.charAt(0)}</>
                )}

                {/* Email */}
                {!comment?.user?.avatarUrl &&
                  !comment?.user?.displayName &&
                  comment.user?.email && <>{comment.user?.email?.charAt(0)}</>}
              </div>

              {/* Comment block */}
              <div className="flex-1 flex flex-col gap-2">
                {/* Header */}
                <div className="flex justify-between min-h-[22px]">
                  {/* User info */}
                  <div className="flex">
                    <div className="font-normal">
                      {comment.user?.displayName}
                    </div>
                    <div className="pl-2 font-medium text-gray-400">
                      {convertISOToLocalDateString(comment?.created_at || '')}
                    </div>
                  </div>

                  {/* Delete button */}
                  <div>
                    {!isSingleResponse && (
                      <CLCustomButton
                        title="Delete"
                        className="hidden group-hover:block p-1 rounded-full hover:bg-slate-100 "
                        onClick={() => {
                          deleteComment(comment, commentIndex);
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.0458 2.72184H10.8458V1.92918C10.8458 1.0921 10.1649 0.411185 9.32785 0.411185L6.67705 0.411133C5.83997 0.411133 5.15918 1.09212 5.15918 1.92913V2.72179L0.958912 2.72184C0.679592 2.72184 0.453125 2.94829 0.453125 3.22762C0.453125 3.50696 0.679578 3.73341 0.958912 3.73341H2.21246V12.2375C2.21246 14.0854 3.71579 15.5886 5.56353 15.5886H10.4411C12.2887 15.5886 13.7922 14.0853 13.7922 12.2375V3.73341H15.0457C15.3251 3.73341 15.5515 3.50696 15.5515 3.22762C15.5517 2.94825 15.3252 2.72184 15.0458 2.72184ZM6.17065 1.92918C6.17065 1.65001 6.39773 1.42288 6.67695 1.42288H9.32789C9.60706 1.42288 9.83419 1.64996 9.83419 1.92918V2.72184H6.17059L6.17065 1.92918ZM12.7808 12.2376C12.7808 13.5275 11.7313 14.5771 10.4413 14.5771H5.56358C4.27363 14.5771 3.22411 13.5276 3.22411 12.2376V3.73345H12.7805L12.7808 12.2376ZM9.22505 11.3746V6.61962C9.22505 6.3403 9.4515 6.11384 9.73083 6.11384C10.0102 6.11384 10.2366 6.34029 10.2366 6.61962V11.3746C10.2366 11.6539 10.0102 11.8803 9.73083 11.8803C9.45146 11.8806 9.22505 11.6539 9.22505 11.3746ZM5.76838 11.3746V6.61962C5.76838 6.3403 5.99483 6.11384 6.27417 6.11384C6.5535 6.11384 6.77995 6.34029 6.77995 6.61962V11.3746C6.77995 11.6539 6.5535 11.8803 6.27417 11.8803C5.99485 11.8806 5.76838 11.6539 5.76838 11.3746Z"
                            fill="#444444"
                          />
                        </svg>
                      </CLCustomButton>
                    )}
                  </div>
                </div>

                {/* Text */}
                <div className="font-normal">{comment.text}</div>

                {/* Attachments */}
                {comment?.run_checklist_comment_files &&
                  comment?.run_checklist_comment_files?.length > 0 && (
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                      {comment.run_checklist_comment_files.map(
                        (attachmentFile, index) => (
                          <div key={index}>
                            {/* File data */}
                            <div className="group flex justify-between items-center gap-2 border border-black/10 rounded-lg px-2 py-2">
                              <div className="flex gap-2 items-center overflow-hidden">
                                {/* Icon */}
                                <div className="flex items-center justify-center min-w-[44px] w-11 h-11 rounded-lg bg-[#CDF0EA]">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.94232 21.5C5.55017 21.5 5.21175 21.3576 4.92705 21.0729C4.64235 20.7882 4.5 20.4498 4.5 20.0576V3.94232C4.5 3.55017 4.64235 3.21175 4.92705 2.92705C5.21175 2.64235 5.55017 2.5 5.94232 2.5H14.0346C14.2356 2.5 14.4253 2.54007 14.6036 2.6202C14.782 2.70033 14.9378 2.80386 15.0711 2.93077L19.0692 6.92882C19.1961 7.06216 19.2996 7.21799 19.3797 7.39633C19.4599 7.57468 19.5 7.76435 19.5 7.96535V20.0576C19.5 20.4498 19.3576 20.7882 19.0729 21.0729C18.7882 21.3576 18.4498 21.5 18.0576 21.5H5.94232ZM14.0634 7.15958V3.6346H5.94232C5.86539 3.6346 5.79487 3.66665 5.73075 3.73075C5.66665 3.79487 5.6346 3.86539 5.6346 3.94232V20.0576C5.6346 20.1346 5.66665 20.2051 5.73075 20.2692C5.79487 20.3333 5.86539 20.3654 5.94232 20.3654H18.0576C18.1346 20.3654 18.2051 20.3333 18.2692 20.2692C18.3333 20.2051 18.3654 20.1346 18.3654 20.0576V7.88073H14.7846C14.5849 7.88073 14.4148 7.81045 14.2743 7.6699C14.1337 7.52937 14.0634 7.35926 14.0634 7.15958Z"
                                      fill="black"
                                    />
                                  </svg>
                                </div>

                                {/* Details */}
                                <div className="overflow-hidden">
                                  <div className="line-clamp-1">
                                    {attachmentFile?.org_file?.file?.name}
                                  </div>
                                  <div className="flex items-center gap-2">
                                    {/* File type */}
                                    {/* <div className="uppercase text-xs	font-medium	text-slate-600	">
                                      {
                                        `${attachmentFile?.org_file?.file?.mimeType}`.split(
                                          '/'
                                        )[1]
                                      }
                                    </div> */}

                                    {/* Download */}
                                    <div>
                                      <CLCustomButton
                                        className="p-0 underline opacity-70"
                                        onClick={() => {
                                          downloadFile(
                                            attachmentFile.url as string,
                                            attachmentFile?.org_file?.file?.name
                                          );
                                        }}
                                      >
                                        Download
                                      </CLCustomButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Add new comment */}
      {!isSingleResponse && (
        <>
          <div className="flex gap-2">
            {/* Profile image */}
            <div className="w-8 h-8 cl_primary_background rounded-full text-lg text-gray-900 flex items-center justify-center uppercase">
              {/* Profile image */}
              {/* Icon */}
              {userData?.avatarUrl && (
                <>
                  <img
                    className="w-8 h-8 rounded-full mx-auto bg-slate-300"
                    src={userData?.avatarUrl}
                    alt=""
                  />
                </>
              )}

              {/* Display name */}
              {!userData?.avatarUrl && userData?.displayName && (
                <>{userData?.displayName?.charAt(0)}</>
              )}

              {/* Email */}
              {!userData?.avatarUrl &&
                !userData?.displayName &&
                userData?.email && <>{userData?.email?.charAt(0)}</>}
            </div>

            <div className="flex-1 flex flex-col gap-2">
              {/* Attachment preview */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                {uploadedFiles.map((uploadedFile, index) => (
                  <div key={index}>
                    <div className="group flex justify-between items-center gap-2 border border-black/10 rounded-lg px-2 py-2">
                      {/* File data */}
                      <div className="flex gap-2 items-center overflow-hidden">
                        {/* Icon */}
                        <div className="flex items-center justify-center min-w-[44px] w-11 h-11 rounded-lg bg-[#CDF0EA]">
                          {uploadedFile?.previewUrl ? (
                            <img
                              src={uploadedFile.previewUrl}
                              className="w-11 h-11 rounded-lg"
                            />
                          ) : (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.94232 21.5C5.55017 21.5 5.21175 21.3576 4.92705 21.0729C4.64235 20.7882 4.5 20.4498 4.5 20.0576V3.94232C4.5 3.55017 4.64235 3.21175 4.92705 2.92705C5.21175 2.64235 5.55017 2.5 5.94232 2.5H14.0346C14.2356 2.5 14.4253 2.54007 14.6036 2.6202C14.782 2.70033 14.9378 2.80386 15.0711 2.93077L19.0692 6.92882C19.1961 7.06216 19.2996 7.21799 19.3797 7.39633C19.4599 7.57468 19.5 7.76435 19.5 7.96535V20.0576C19.5 20.4498 19.3576 20.7882 19.0729 21.0729C18.7882 21.3576 18.4498 21.5 18.0576 21.5H5.94232ZM14.0634 7.15958V3.6346H5.94232C5.86539 3.6346 5.79487 3.66665 5.73075 3.73075C5.66665 3.79487 5.6346 3.86539 5.6346 3.94232V20.0576C5.6346 20.1346 5.66665 20.2051 5.73075 20.2692C5.79487 20.3333 5.86539 20.3654 5.94232 20.3654H18.0576C18.1346 20.3654 18.2051 20.3333 18.2692 20.2692C18.3333 20.2051 18.3654 20.1346 18.3654 20.0576V7.88073H14.7846C14.5849 7.88073 14.4148 7.81045 14.2743 7.6699C14.1337 7.52937 14.0634 7.35926 14.0634 7.15958Z"
                                fill="black"
                              />
                            </svg>
                          )}
                        </div>

                        {/* Details */}
                        <div className="overflow-hidden">
                          <div className="line-clamp-1">
                            {uploadedFile.file.name}
                          </div>
                          <div className="uppercase text-xs font-medium text-slate-600">
                            {`${uploadedFile.file.type}`.split('/')[1]}
                          </div>
                        </div>
                      </div>

                      {/* Delete icon */}
                      <div className="visible md:invisible md:group-hover:visible">
                        <div
                          className="w-6 h-6 rounded-full bg-black/40 cursor-pointer flex items-center justify-center"
                          onClick={() => {
                            deleteUploadedFile(index);
                          }}
                        >
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.574173 0.575638C0.808488 0.341324 1.18839 0.341324 1.4227 0.575638L3.99844 3.15137L6.57417 0.575638C6.80849 0.341324 7.18839 0.341324 7.4227 0.575638C7.65702 0.809953 7.65702 1.18985 7.4227 1.42417L4.84697 3.9999L7.4227 6.57564C7.65702 6.80995 7.65702 7.18985 7.4227 7.42417C7.18839 7.65848 6.80849 7.65848 6.57417 7.42417L3.99844 4.84843L1.4227 7.42417C1.18839 7.65848 0.808488 7.65848 0.574173 7.42417C0.339859 7.18985 0.339859 6.80995 0.574173 6.57564L3.14991 3.9999L0.574173 1.42417C0.339859 1.18985 0.339859 0.809953 0.574173 0.575638Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full border rounded-lg px-4 py-3 focus-within:border-yellow-400">
                {/* Comment text area */}
                <CLPrimaryCustomTextarea
                  style={{
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                    padding: '0px',
                    borderRadius: '0px'
                  }}
                  className="w-full h-30 resize-none"
                  placeholder={'Add a comment'}
                  value={commentText}
                  rows={3}
                  onKeyDown={handleKeyDown}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCommentText(event.target.value);
                  }}
                ></CLPrimaryCustomTextarea>

                {/* Footer */}
                <div className="flex justify-between">
                  {/* Left side */}
                  <div className="flex gap-2">
                    {/* Attachment icon */}
                    <CLTooltip content="Attachments">
                      <CLCustomButton>
                        <label htmlFor="comment-attachment-upload">
                          <svg
                            className="cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            height="20"
                            viewBox="0 -960 960 960"
                            width="20"
                          >
                            <path
                              d="M460-80q-92 0-156-64t-64-156v-420q0-66 47-113t113-47q66 0 113 47t47 113v380q0 42-29 71t-71 29q-42 0-71-29t-29-71v-350q0-13 8.5-21.5T390-720q13 0 21.5 8.5T420-690v350q0 17 11.5 28.5T460-300q17 0 28.5-11.5T500-340v-380q0-42-29-71t-71-29q-42 0-71 29t-29 71v420q0 66 47 113t113 47q66 0 113-47t47-113v-390q0-13 8.5-21.5T650-720q13 0 21.5 8.5T680-690v390q0 92-64 156T460-80Z"
                              fill="black"
                              fillOpacity="0.6"
                            />
                          </svg>
                        </label>
                        <input
                          className="hidden"
                          id="comment-attachment-upload"
                          type="file"
                          multiple
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleFileUpload(event);
                          }}
                        />
                      </CLCustomButton>
                    </CLTooltip>
                  </div>

                  {/* Right side */}
                  <div>
                    {/* Comment button */}
                    <CLPrimaryCustomButton
                      className="text-xs px-2 py-1 font-medium"
                      onClick={() => {
                        addComment();
                      }}
                      disabled={
                        (!commentText && uploadedFiles?.length == 0) ||
                        isCommentSaving
                      }
                    >
                      Comment
                    </CLPrimaryCustomButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
