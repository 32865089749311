export function isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function isValidUUID(uuid: string) {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
    return uuidRegex.test(uuid);
}

export function isValidURL(url: string) {
    const urlRegex = /^(https?|ftp|http):\/\/[^\s/$.?#].[^\s]*$/;

    try {
        if (urlRegex.test(url)) {
            // Try to parse the URL
            new URL(url);

            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export function getOrgInviteLink(orgId: string, email: string) {
    const invitationDataBase64Encoded = Buffer.from(orgId + ":" + email, 'binary').toString('base64');
    const inviteUrl = "https://app.checklist.gg/invite/" + invitationDataBase64Encoded;

    return inviteUrl;
}

export function convertYoutubeAndVimeoEmbedURL(url: string) {
    if (url && isValidURL(url)) {
        // Parse the URL for Vimeo and Youtube
        const urlObject = new URL(url);

        if (
            urlObject.origin == 'https://vimeo.com' ||
            urlObject.origin == 'https://youtu.be'
        ) {
            // Parse the url
            if (urlObject.pathname && urlObject.pathname[0] == '/') {
                const paths = urlObject.pathname.split('/');
                if (paths && paths.length > 1 && paths[1]) {
                    if (urlObject.origin == 'https://vimeo.com') {
                        // https://vimeo.com/370868863
                        // https://player.vimeo.com/video/370868863

                        // Update, embeddable URL
                        return 'https://player.vimeo.com/video/' + paths[1];
                    } else if (urlObject.origin == 'https://youtu.be') {
                        // https://youtu.be/VG4mceQHMZg
                        // https://www.youtube.com/embed/VG4mceQHMZg

                        // Update, embeddable URL
                        return 'https://www.youtube.com/embed/' + paths[1];
                    }
                }
            }
        } else if (
            urlObject.origin == 'https://www.youtube.com' &&
            urlObject.search
        ) {
            // https://www.youtube.com/watch?v=VG4mceQHMZg
            // https://www.youtube.com/embed/VG4mceQHMZg

            const urlParams = new URLSearchParams(urlObject.search);
            const videoId = urlParams.get('v');

            return 'https://www.youtube.com/embed/' + videoId;
        }
    }

    return url;
}

export async function copyToClipboard(text: string): Promise<void> {
    try {
        await navigator.clipboard.writeText(text);
    } catch (err) {
        console.error('Failed to copy text: ', err);
    }
}

export function openInNewTab(url: string): void {
    window.open(url, '_blank');
}

export function convertISOToLocalDateString(isoTimeString: string) {
    if (!isoTimeString) {
        return isoTimeString;
    }

    // Output format is Nov 03, 2023, 01:24 PM
    return new Date(isoTimeString as string).toLocaleDateString(
        'en-US',
        // { month: 'short', hour12: true }
        {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }
    );
}

export function shareableChecklistUrl(checklistId: string, checklistTitle: string) {
    const shareableUrl =
        (window?.location?.hostname == 'localhost'
            ? 'http://'
            : 'https://') +
        (window?.location?.host ||
            'app.checklist.gg') +
        '/' +
        getChecklistCleanURL(checklistId, checklistTitle);

    return shareableUrl;
}

//Function to get checklistUrl and encode the url
export function getChecklistCleanURL(checklistId: string, checklistTitle: string) {
    if (checklistId) {

        // Format the checklist Title
        let shortenedTitle = (checklistTitle || "").replace(/ /g, '-');
        shortenedTitle = shortenedTitle
            .replace(/[^a-zA-Z0-9-]/g, '')
            .replace(/-+/g, '-');

        // Removing the hyphen from the last letter
        if (shortenedTitle.endsWith('-')) {
            shortenedTitle = shortenedTitle.slice(0, -1);
        }
        // Removing the hyphen from the first letter
        if (shortenedTitle.startsWith('-')) {
            shortenedTitle = shortenedTitle.slice(1);
        }
        shortenedTitle = shortenedTitle.toLowerCase();

        // Convert stringChecklistId to a Buffer
        const bufferHex = Buffer.from(checklistId.replace(/-/g, ''), 'hex');

        // Convert Buffer to base64 string
        const base64ChecklistId = bufferHex
            .toString('base64')
            .replace(/\+/g, '.') // Replace '+' with '.'
            .replace(/\//g, '_') // Replace '/' (space) with '_'
            .replace(/=+$/, '') // Remove trailing '=' characters
            .replace(/^/, '-');

        const encodedURL = shortenedTitle + base64ChecklistId;

        return encodedURL
    }

    return null; // Return null or handle empty scenarios
}


//Function to get checklistUrl and decode the url
export function decryptChecklistId(encryptedChecklistId: string): string | null {
    // Validation
    if (!encryptedChecklistId) {
        return encryptedChecklistId;
    }

    // Define a regular expression to match UUID v4 format
    const UUIDRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    // Split the URL to extract the encoded checklistId
    const parts = encryptedChecklistId.split('-');
    const encodedChecklistId = parts.pop();

    if (encodedChecklistId) {
        // Replace '.' with '+' and '_' with ' ' (space) to revert base64 encoding
        const base64Encoded = encodedChecklistId
            .replace(/\./g, '+')
            .replace(/_/g, '/')
            .padEnd((encodedChecklistId.length + 3) & ~3, '=');

        // Decode the base64 string to get the hexadecimal representation
        const bufferHex = Buffer.from(base64Encoded, 'base64').toString('hex');

        // Insert dashes in the hexadecimal string to match the original format
        const checklistIdWithDashes = [
            bufferHex.slice(0, 8),
            bufferHex.slice(8, 12),
            bufferHex.slice(12, 16),
            bufferHex.slice(16, 20),
            bufferHex.slice(20),
        ].join('-');

        // Check if the decoded string matches the UUID format
        if (UUIDRegex.test(checklistIdWithDashes)) {
            return checklistIdWithDashes;
        }
    }

    return null;
}