const metaInfoConstants = {
    DEFAULT_META_INFO: {
        description:
            'checklist.gg is an AI-driven checklists, processes and SOPs management tool designed to help organizations streamline and optimize their workflow. It allows users to create, assign, and track tasks and checklists in real-time, ensuring that all team members are on the same page and that tasks are completed efficiently and effectively.',
        keywords:
            'checklist software, SOP software, checklist management, SOP management, process management, AI checklist, Checklist manifesto',
        metaTitle: 'An AI-driven tool for checklists, processes, and SOPs',
        url: 'https://app.checklist.gg/choose-projects/',
        image:
            `https://app.checklist.gg/api/og?title=` +
            'An AI-driven tool for checklists, processes, and SOPs'
    }
}

export default metaInfoConstants;