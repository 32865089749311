import { convertYoutubeAndVimeoEmbedURL } from '@/services/utils/utils.service';
import { RunComponentCommonProps } from '@/types/run-components.type';

export default function RunEmbedComponent({
  componentMapping
}: RunComponentCommonProps) {
  return (
    <div>
      {componentMapping?.component_embed?.url && (
        <div className="w-full h-[400px] rounded-lg overflow-hidden">
          <iframe
            src={convertYoutubeAndVimeoEmbedURL(
              componentMapping?.component_embed?.url?.startsWith('https://') ||
                componentMapping?.component_embed?.url?.startsWith('http://')
                ? componentMapping?.component_embed?.url
                : 'https://' + componentMapping?.component_embed?.url
            )}
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}
    </div>
  );
}
