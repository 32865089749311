import { gql } from '@apollo/client';

export const GET_CHECKLISTS_WITH_PROJECT_DETAILS_BY_PROJECT_ID_QUERY = gql`
    query GetChecklistByProjectId($id: uuid!) {
        projects(where: {id: {_eq: $id} _and: {is_deleted: {_is_null: true}}}) {
            id
            name
            description
            checklists(order_by: {updated_at: desc}, where: {parent_id: {_is_null: true}, _and: {is_deleted: {_is_null: true}, task_id: {_is_null: true}}}) {
                id
                title
                parent_id
                created_at
                updated_at
                checklist_version_id
                userByCreatedBy {
                    id
                    displayName
                    email
                }
            }
        }
    }  
`;

export const GET_CHECKLISTS_BY_PROJECT_ID_QUERY = gql`
    query GetChecklistByProjectId($id: uuid!) {
        checklist(where: {project_id: {_eq: $id}, _and: {parent_id: {_is_null: true}}}) {
            id
            title
        }
    }
`;

export const CREATE_PROJECT_MUTATION = gql`
    mutation CreateProject($orgId: uuid!, $projectName: String!, $description: String!){
        insert_projects_one(object: {
            org_id: $orgId, 
            name : $projectName,
            description : $description
        }) 
        {
            id
            name
        }
    }
`;

export const CREATE_PROJECT_WITH_MEMBERS_MUTATION = gql`
  mutation CreateProjectWithMembers($projects: [projects_insert_input!]!, $projectUsers: [project_users_insert_input!]!) {
    insert_projects(objects: $projects) {
      affected_rows
    }
    insert_project_users(objects: $projectUsers) {
      affected_rows
    }
  }  
`;

export const UPDATE_PROJECT_MUTATION = gql`
    mutation UpdateProjectMutation($projectId: uuid!, $set: projects_set_input!) {
        update_projects_by_pk (
            pk_columns: {id: $projectId}
            _set: $set
          ) {
            id
            name
          }
    }
`;

export const ADD_A_MEMBER_TO_PROJECT_MUTATION = gql`
    mutation InviteAMemberToProject($projectId: uuid!, $userId: uuid!, $customRoleId: uuid!) {
        insert_project_users_one(object: {
            project_id: $projectId, 
            user_id: $userId, 
            custom_role_id: $customRoleId}) {
            id
            project_id
            user_id
        }
    }
`;

export const ADD_MULTIPLE_MEMBERS_TO_PROJECT_MUTATION = gql`
    mutation InviteMultipleMembersToProject($projectUsers: [project_users_insert_input!]!) {
        insert_project_users(
            objects: $projectUsers
        ) {
            affected_rows
            returning {
                id
                project_id
                user {
                    id
                    displayName
                    email
                    avatarUrl
                }
                custom_role_id
                custom_role {
                    id
                    title
                }
            }
        }
    }
`;

export const CREATE_MULTIPLE_PROJECTS_AND_ADD_A_MEMBER_MUTATION = gql`
    mutation CreateMultipleProjectsAndInviteAMemberMutation($projects: [projects_insert_input!]!, $projectUsers: [project_users_insert_input!]!) {
        insert_projects(
            objects: $projects
        ) {
            affected_rows
        }

        insert_project_users(
            objects: $projectUsers
        ) {
            affected_rows
        }
    }
`;

export const DELETE_PROJECT_USER_BY_ID_MUTATION = gql`
    mutation DeleteProjectUsersByIdMutation($id: uuid!) {
        delete_project_users_by_pk (
            id: $id
        ) {
            id
        }
    }
`;

export const UPDATE_PROJECT_USER_ROLE_BY_ID_MUTATION = gql`
  mutation updateProjectUserRoleByIdMutation($id: uuid!, $set: project_users_set_input!) {
    update_project_users_by_pk(
      pk_columns: { id: $id }, 
      _set: $set) {
      id
    }
  }
`;

export const GET_PROJECT_USERS_QUERY = gql`
    query getProjectUsersQuery($projectId: uuid!) {
        project_users(where: {project_id: {_eq: $projectId}}) {
            id
            user {
                id
                displayName
                email
            }
        }
    }  
`;

export const DELETE_PROJECT_INVITE_BY_ID_MUTATION = gql`
  mutation DeleteProjectInviteByIdMutation($id: uuid!) {
    delete_project_invites_by_pk (
      id: $id
    ) {
      id
    }
  }
`;

export const DELETE_PROJECT_INVITE_BY_EMAIL_WITH_ORGID_MUTATION = gql`
  mutation DeleteProjectInviteByEmailWithOrgIdMutation($orgId: uuid!, $email: citext) {
        delete_project_invites(where: {email: {_eq: $email}, org_id: {_eq: $orgId}}) {
            affected_rows
        }
  }
`;

export const DELETE_PROJECT_BY_ID_MUTATION = gql`
  mutation DeleteProjectByIdMutation($projectId: uuid!, $set: projects_set_input!) {
    update_projects_by_pk (
      pk_columns: {id: $projectId}
      _set: $set
    ) {
      id
    }
  }
`;

export const GetProjectActivityQuery = gql`
    query OrgProjectActivityQuery($id: uuid!) {
        projects(where: {id: {_eq: $id}, is_deleted: {_is_null: true}}, order_by: {created_at: asc}) {
        id
        name
        checklist_created: events_aggregate(where: {project_id: {_eq: $id}, event_type: {_eq: "checklist_created"}, is_project_deleted: {_is_null: true}, is_checklist_deleted: {_is_null: true}}) {
            aggregate {
            count
            }
        }
        run_created: events_aggregate(where: {project_id: {_eq: $id}, event_type: {_eq: "run_created"}, is_checklist_deleted: {_is_null: true}, is_run_deleted: {_is_null: true}}) {
            aggregate {
            count
            }
            nodes {
                run_id
               checklist {
                id
                title
               }
               user {
                id
                displayName
               }
              }
        }
        run_completed: events_aggregate(where: {project_id: {_eq: $id}, event_type: {_eq: "run_completed"}, is_checklist_deleted: {_is_null: true}, is_run_deleted: {_is_null: true}}) {
            aggregate {
            count
            }
            nodes {
                run_id
               checklist {
                id
                title
               }
               user {
                id
                displayName
               }
            }
        }
        run_overdue: events_aggregate(where: {project_id: {_eq: $id}, event_type: {_eq: "run_created"}, is_checklist_deleted: {_is_null: true}, is_run_deleted: {_is_null: true}, run_due_at: {_lt: "now()"}}) {
            aggregate {
            count
            }
            nodes {
                run_id
               checklist {
                id
                title
               }
               user {
                id
                displayName
               }
            }
        }
        run_inprogress: events_aggregate(where: {project_id: {_eq: $id}, event_type: {_eq: "run_inprogress"}, is_checklist_deleted: {_is_null: true}, is_run_deleted: {_is_null: true}}) {
            aggregate {
            count
            }
            nodes {
                run_id
               checklist {
                id
                title
               }
               user {
                id
                displayName
               }
            }
        }
        }
    }
`;

export const GetMembersActivityQuery = gql`
    query OrgMembersActivityQuery($id: uuid!) {
        projects(where: {id: {_eq: $id}, is_deleted: {_is_null: true}}, order_by: {created_at: asc}) {
        id
        checklist_created: events_aggregate(where: {project_id: {_eq: $id}, event_type: {_eq: "checklist_created"}, is_project_deleted: {_is_null: true}, is_checklist_deleted: {_is_null: true}}) {
            aggregate {
            count
            }
        }
        run_created: events_aggregate(where: {project_id: {_eq: $id}, event_type: {_eq: "run_created"}, is_project_deleted: {_is_null: true}, is_checklist_deleted: {_is_null: true}, is_run_deleted: {_is_null: true}}) {
            aggregate {
            count
            }
            nodes {
                user {
                 displayName
                }
               }
        }
        run_completed: events_aggregate(where: {project_id: {_eq: $id}, event_type: {_eq: "run_completed"}, is_project_deleted: {_is_null: true}, is_checklist_deleted: {_is_null: true}, is_run_deleted: {_is_null: true}}) {
            aggregate {
            count
            }
        }
        run_overdue: events_aggregate(where: {project_id: {_eq: $id}, event_type: {_eq: "run_created"}, is_project_deleted: {_is_null: true}, is_checklist_deleted: {_is_null: true}, is_run_deleted: {_is_null: true}, run_due_at: {_lt: "now()"}}) {
            aggregate {
            count
            }
        }
        run_inprogress: events_aggregate(where: {project_id: {_eq: $id}, event_type: {_eq: "run_inprogress"}, is_checklist_deleted: {_is_null: true}, is_run_deleted: {_is_null: true}}) {
            aggregate {
            count
            }
        }
        }
    }
`;