import CommonConstants from '@/constants/common-constants';
import metaInfoConstants from '@/constants/meta-info-constants';
import {
  GET_RUN_BY_ID_WITH_CHECKLIST_VERSION_QUERY,
  GET_RUN_CHECKLIST_INSTRUCTION_BY_RUN_ID_QUERY,
  INSERT_RUN_CHECKLIST_MUTATION
} from '@/graphql/runs.gql';
import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { Run, RunChecklist } from '@/types/run.type';
import { useMutation, useQuery } from '@apollo/client';
import axios, { AxiosError, CancelToken } from 'axios';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { shallow } from 'zustand/shallow';
import MetaInfo from '../meta-info/meta-info.component';
import RunBuilderComponent from './run-builder.component';

export type RunChecklistTree = {
  checklist: RunChecklist;
  children: RunChecklistTree[];
  parent?: RunChecklistTree;
  isFirstNode?: boolean;
  isLastNode?: boolean;
  lastChildNode?: RunChecklistTree;
};

interface RunChecklistByTaskId {
  [key: string]: RunChecklist;
}

export default function RunByIdComponent() {
  // Variables
  const router = useRouter();
  const { runId, preview } = router.query;

  // State
  const [run, setRun] = useState<Run>();

  // Store
  const [reset] = useRunChecklistStore(s => [s.reset], shallow);

  // GrpahQL
  const { data, loading, error } = useQuery<{ runs: Run[] }>(
    GET_RUN_BY_ID_WITH_CHECKLIST_VERSION_QUERY,
    {
      variables: { id: runId },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (data?.runs?.[0]) {
      // Reset the store
      reset();

      const run = data.runs[0];
      setRun(run);
    }
  }, [data]);

  // Loading
  if (loading || !run) {
    return (
      <div className="px-4 md:px-10 pt-6 pb-6">
        <div className="bg-[#EDE4E0] h-7 rounded-lg w-52 max-w-full mb-6"></div>

        <ul className="grid grid-cols-1 gap-3 mt-8">
          <li className="bg-[#EDE4E0] rounded-lg h-12"></li>
          <li className="bg-[#EDE4E0] rounded-lg h-12"></li>
          <li className="bg-[#EDE4E0] rounded-lg h-12"></li>
          <li className="bg-[#EDE4E0] rounded-lg h-12"></li>
        </ul>
      </div>
    );
  }

  // Not found
  if (
    !data ||
    !data?.runs ||
    data?.runs?.length == 0 ||
    !data.runs[0]?.checklist_version_id ||
    !data.runs[0]?.checklist_version?.checklistByVersionChecklistId
  ) {
    router.push('/assigned');
    return <></>;
  }

  return (
    <>
      <Head>
        <title>{run?.name || 'Untitled'} | Checklist GG</title>
        <MetaInfo metaInfo={metaInfoConstants.DEFAULT_META_INFO}></MetaInfo>
      </Head>

      <RenderRunComponent
        runId={runId as string}
        run={run}
        isPreview={preview === 'true' ? true : false}
        isPublic={false}
        isSingleResponse={false}
      />
    </>
  );
}

/**
 * Run will be rendered from multiple places
 *
 * 1. Assigned run
 * 2. Public run
 * 3. Builder preview
 * 4. Single response view in Responses
 */
export function RenderRunComponent({
  runId,
  run,
  isPreview,
  isPublic,
  isSingleResponse
}: {
  runId: string;
  run: Run;
  isPreview: boolean;
  isPublic: boolean;
  isSingleResponse: boolean;
}) {
  // Variables

  // States
  const [runChecklistTree, setRunChecklistTree] = useState<RunChecklistTree>();

  // Store
  const [
    setIsPreview,
    setIsPublic,
    setIsStatusIndicatorDisabled,
    setIsSingleResponse,
    reset
  ] = useRunChecklistStore(
    s => [
      s.setIsPreview,
      s.setIsPublic,
      s.setIsStatusIndicatorDisabled,
      s.setIsSingleResponse,
      s.reset
    ],
    shallow
  );

  // GraphQL
  const [insertRunChecklistMutation] = useMutation(
    INSERT_RUN_CHECKLIST_MUTATION
  );

  useEffect(() => {
    // Set preview mode
    setIsPreview(isPreview === true ? true : false);

    // Set public mode
    setIsPublic(isPublic === true ? true : false);

    setIsStatusIndicatorDisabled(isSingleResponse === true ? true : false);

    // Set single response view flag
    setIsSingleResponse(isSingleResponse === true ? true : false);

    if (run) {
      const runObject = JSON.parse(JSON.stringify(run));

      const runChecklistByTaskId: RunChecklistByTaskId = {};
      formRunChecklistByTaskId(runObject, runChecklistByTaskId);

      const newRunChecklists: RunChecklist[] = [];
      formRunChecklistTree(runObject, runChecklistByTaskId, newRunChecklists);

      // Insert all missed runChecklist
      if (
        isPreview !== true &&
        isPublic !== true &&
        isSingleResponse !== true
      ) {
        insertNewRunChecklist(newRunChecklists);
      }
    }
  }, [run, isPreview, isPublic]);

  function formRunChecklistByTaskId(
    runObject: Run,
    runChecklistByTaskId: RunChecklistByTaskId
  ) {
    if (runObject?.run_checklists && runObject?.run_checklists?.length > 0) {
      runObject.run_checklists.forEach(runChecklist => {
        if (runChecklist.task_id) {
          runChecklistByTaskId[runChecklist.task_id] = runChecklist;
        }
      });
    }
  }

  function insertNewRunChecklist(newRunChecklists: RunChecklist[]) {
    if (newRunChecklists?.length > 0) {
      insertRunChecklistMutation({
        variables: {
          checklist: newRunChecklists
        }
      });
    }
  }

  function createRunChecklist(
    versionChecklist: RunChecklist,
    runChecklistByTaskId: RunChecklistByTaskId,
    runObject: Run,
    newRunChecklists: RunChecklist[]
  ) {
    const newRunChecklist: RunChecklist = {
      id: uuidv4(),
      task_id: versionChecklist?.task_id,
      run_id: runId as string,
      run_checklist_id: runObject?.run_checklist_id,
      assigned_by: runObject?.created_by
    };

    if (newRunChecklist?.task_id) {
      runChecklistByTaskId[newRunChecklist.task_id] = newRunChecklist;
    }

    newRunChecklists.push(newRunChecklist);
    return newRunChecklist;
  }

  function updateRunChecklistProps(
    versionChecklist: RunChecklist,
    runChecklistByTaskId: RunChecklistByTaskId,
    runObject: Run,
    newRunChecklists: RunChecklist[]
  ) {
    // Create new RunChecklist, if not found
    if (
      versionChecklist.task_id &&
      !runChecklistByTaskId[versionChecklist.task_id]
    ) {
      createRunChecklist(
        versionChecklist,
        runChecklistByTaskId,
        runObject,
        newRunChecklists
      );
    }

    // Take RunChecklist status
    if (versionChecklist.task_id) {
      versionChecklist['run_checklist_task_id'] =
        runChecklistByTaskId[versionChecklist.task_id].id;
      versionChecklist['run_checklist_id'] =
        runChecklistByTaskId[versionChecklist.task_id].run_checklist_id;
      versionChecklist['is_completed'] =
        runChecklistByTaskId[versionChecklist.task_id].is_completed;
      versionChecklist['run_id'] =
        runChecklistByTaskId[versionChecklist.task_id].run_id;
      versionChecklist['checklist_status_indicators_id'] =
        runChecklistByTaskId[
          versionChecklist.task_id
        ].checklist_status_indicators_id;
    }
  }

  function formChildRunChecklistTree(
    node: RunChecklistTree,
    allChildRunChecklist: RunChecklist[],
    rootNode: RunChecklistTree,
    runChecklistByTaskId: RunChecklistByTaskId,
    runObject: Run,
    newRunChecklists: RunChecklist[]
  ) {
    // Capture last child node
    rootNode.lastChildNode = node;

    const myChildrenRunChecklist = allChildRunChecklist.filter(
      checklist => checklist.parent_id === node.checklist.id
    );

    myChildrenRunChecklist.sort((a, b) => {
      return (a.order_number as number) - (b.order_number as number);
    });

    node.children = myChildrenRunChecklist.map((checklist, index) => {
      updateRunChecklistProps(
        checklist,
        runChecklistByTaskId,
        runObject,
        newRunChecklists
      );

      return {
        checklist: checklist,
        children: [],
        parent: node,
        isFirstNode: index === 0,
        isLastNode: index === myChildrenRunChecklist.length - 1
      };
    });

    node.children.forEach(childNode => {
      formChildRunChecklistTree(
        childNode,
        allChildRunChecklist,
        rootNode,
        runChecklistByTaskId,
        runObject,
        newRunChecklists
      );
    });
  }

  function formRunChecklistTree(
    runObject: Run,
    runChecklistByTaskId: RunChecklistByTaskId,
    newRunChecklists: RunChecklist[]
  ) {
    if (runObject?.checklist_version?.checklistByVersionChecklistId) {
      const versionChecklistObject =
        runObject.checklist_version.checklistByVersionChecklistId;

      updateRunChecklistProps(
        versionChecklistObject,
        runChecklistByTaskId,
        runObject,
        newRunChecklists
      );

      const _rootNode = {
        checklist: versionChecklistObject,
        children: [],
        parent: undefined
      } as RunChecklistTree;

      formChildRunChecklistTree(
        _rootNode,
        versionChecklistObject?.checklistsByChecklistId as RunChecklist[],
        _rootNode,
        runChecklistByTaskId,
        runObject,
        newRunChecklists
      );

      setRunChecklistTree(_rootNode);
    }
  }

  return (
    <>
      {/* Body */}
      {runChecklistTree && (
        <RunBuilderComponent
          runObject={run}
          runChecklistTree={runChecklistTree}
        />
      )}
    </>
  );
}

function GetRunChecklistInstructionsMappingComponent({
  runId
}: {
  runId: string;
}) {
  // Variables

  // Store
  const [setRunChecklistInstructionsMappingObject] = useRunChecklistStore(
    s => [s.setRunChecklistInstructionsMappingObject],
    shallow
  );

  // GraphQL
  const { data, loading, error } = useQuery<{ runs: Run[] }>(
    GET_RUN_CHECKLIST_INSTRUCTION_BY_RUN_ID_QUERY,
    {
      variables: { id: runId },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (data?.runs[0]) {
      const runObject = data?.runs[0];
      const componentMappingObject: { [key: string]: boolean } = {};

      if (runObject?.checklist_version?.checklistByVersionChecklistId) {
        if (
          runObject?.checklist_version?.checklistByVersionChecklistId?.id &&
          runObject?.checklist_version?.checklistByVersionChecklistId
            ?.checklist_component_mappings &&
          runObject?.checklist_version?.checklistByVersionChecklistId
            ?.checklist_component_mappings?.length > 0
        ) {
          componentMappingObject[
            runObject.checklist_version.checklistByVersionChecklistId.id
          ] = true;
        }

        // Check for all children
        if (
          runObject?.checklist_version?.checklistByVersionChecklistId
            ?.checklistsByChecklistId &&
          runObject?.checklist_version?.checklistByVersionChecklistId
            ?.checklistsByChecklistId?.length > 0
        ) {
          const tasks =
            runObject?.checklist_version?.checklistByVersionChecklistId
              ?.checklistsByChecklistId;

          tasks.forEach(task => {
            if (
              task?.id &&
              task?.checklist_component_mappings &&
              task?.checklist_component_mappings?.length > 0
            ) {
              componentMappingObject[task.id] = true;
            }
          });
        }
      }

      setRunChecklistInstructionsMappingObject(componentMappingObject);
    }
  }, [data]);

  return <></>;
}

function GetPublicRunChecklistInstructionsMappingComponent({
  runId
}: {
  runId: string;
}) {
  // Variables

  // Store
  const [setRunChecklistInstructionsMappingObject] = useRunChecklistStore(
    s => [s.setRunChecklistInstructionsMappingObject],
    shallow
  );

  useEffect(() => {
    const { token: cancelToken, cancel } = axios.CancelToken.source();

    // Get checklist instruction mappings
    fetchChecklistInstructionMappings(runId, cancelToken);

    return () => {
      cancel('Cancelled');
    };
  }, []);

  async function fetchChecklistInstructionMappings(
    runId: string,
    cancelToken: CancelToken
  ) {
    // Fetch all instruction mappings
    try {
      const payload = {
        runId: runId
      };

      const response = await axios.post(
        CommonConstants.API_PREFIX +
          '/public/run/get-checklist-instruction-mappings',
        payload,
        {
          cancelToken: cancelToken
        }
      );

      if (
        response?.data?.data?.public_runs &&
        response?.data?.data?.public_runs?.length > 0
      ) {
        const result = response?.data?.data?.public_runs[0];

        updateChecklistInstructionMappingsToStore(result);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error?.response?.data?.error?.message || error.message);
      } else {
        console.error('Something Went wrong. Please try again');
      }
    }
  }

  function updateChecklistInstructionMappingsToStore(runObject: Run) {
    if (runObject) {
      const componentMappingObject: { [key: string]: boolean } = {};

      if (runObject?.checklist_version?.checklistByVersionChecklistId) {
        if (
          runObject?.checklist_version?.checklistByVersionChecklistId?.id &&
          runObject?.checklist_version?.checklistByVersionChecklistId
            ?.checklist_component_mappings &&
          runObject?.checklist_version?.checklistByVersionChecklistId
            ?.checklist_component_mappings?.length > 0
        ) {
          componentMappingObject[
            runObject.checklist_version.checklistByVersionChecklistId.id
          ] = true;
        }

        // Check for all children
        if (
          runObject?.checklist_version?.checklistByVersionChecklistId
            ?.checklistsByChecklistId &&
          runObject?.checklist_version?.checklistByVersionChecklistId
            ?.checklistsByChecklistId?.length > 0
        ) {
          const tasks =
            runObject?.checklist_version?.checklistByVersionChecklistId
              ?.checklistsByChecklistId;

          tasks.forEach(task => {
            if (
              task?.id &&
              task?.checklist_component_mappings &&
              task?.checklist_component_mappings?.length > 0
            ) {
              componentMappingObject[task.id] = true;
            }
          });
        }
      }

      setRunChecklistInstructionsMappingObject(componentMappingObject);
    }
  }

  return <></>;
}
