import { Checklist } from '@/types/checklist.type';
import { create } from 'zustand';

interface ResponsesChecklistState {
    checklist: Checklist | undefined;
    hasChecklistUpdatePermission: boolean;
    initChecklist: (checklist: Checklist) => void;
    setHasChecklistUpdatePermission: (hasUpdateChecklistPermission: boolean) => void;
    reset: () => void;
}

export const useAnalyticsChecklistStore = create<ResponsesChecklistState>((set, get) => ({
    checklist: undefined,
    hasChecklistUpdatePermission: false,
    initChecklist: (checklist: Checklist) => {
        set((state) => ({ checklist }))
    },
    setHasChecklistUpdatePermission: (hasChecklistUpdatePermission: boolean) => {
        set({ hasChecklistUpdatePermission })
    },
    reset: () => {
        set((state) => ({ checklist: undefined }))
    }
}));
