import BuilderLayoutMenuComponent from '@/components/builder-layout/builder-layout-menu.component';

interface Props {
  children: React.ReactNode;
}

export default function BuilderLayout({ children }: Props) {
  return (
    <div className="h-screen flex flex-col">
      {/* Menu */}
      <div>
        <BuilderLayoutMenuComponent />
      </div>

      {/* Body */}
      <div className="flex-grow h-full overflow-y-auto">{children}</div>
    </div>
  );
}
