import { GET_AGENT_MANAGER_RESULTS_BY_ID_QUERY } from '@/graphql/agent.gql';
import { AgentResult } from '@/types/agent.type';
import { useQuery } from '@apollo/client';

interface Props {
  agentManagerId: string;
  agentId: string;
}

export function AgentResultsComponent({
  agentManagerId,
  agentId,
  ...props
}: Props) {
  // Variables

  // GraphQL
  const { loading, error, data } = useQuery(
    GET_AGENT_MANAGER_RESULTS_BY_ID_QUERY,
    {
      variables: { agentManagerId: agentManagerId },
      fetchPolicy: 'network-only'
    }
  );

  if (
    !data ||
    !data.agent_managers ||
    data.agent_managers.length == 0 ||
    !data.agent_managers[0].agents ||
    data.agent_managers[0].agents.length == 0
  ) {
    return <></>;
  }

  let agentResults: AgentResult[] = [];
  if (
    data.agent_managers[0].agents &&
    data.agent_managers[0].agents.length > 0
  ) {
    data.agent_managers[0].agents.forEach(
      (agent: { agent_results: AgentResult[] }) => {
        agentResults = [...agentResults, ...agent.agent_results];
      }
    );
  }

  return (
    <div className="py-4 flex flex-col gap-6">
      {agentResults &&
        agentResults.map((agentResult, index) => (
          <div className="flex flex-col gap-2" key={index}>
            <div className="p-2 rounded-md bg-[#f3f3f3]">
              <span className="font-bold">Thoughts:</span>{' '}
              {agentResult?.thoughts ||
                (agentResult?.is_final_answer && 'Preparing a final answer')}
            </div>

            <div className="flex flex-col p-2 rounded-md divide-y divide-gray-300 bg-[#f3f3f3]">
              <div className="pb-2 whitespace-pre-line">
                <span className="font-bold text-green-500">Completing:</span>{' '}
                {agentResult?.action_input ||
                  (agentResult?.is_final_answer && 'Final answer is ready.')}
              </div>

              <div
                className="pt-2 whitespace-pre-line"
                dangerouslySetInnerHTML={{ __html: agentResult?.results || '' }}
              ></div>
            </div>
          </div>
        ))}
    </div>
  );
}
