import { gql } from "@apollo/client";

export const GET_ORGANIZATION_ROLES = gql`
    query getOrganizationRoles{
        custom_roles(where: {type: {_eq: "organization"}}, order_by: {created_at: asc}) {
        id
        title
        description
        }
    }
`;

export const GET_PROJECT_ROLES = gql`
    query getProjectRoles{
        custom_roles(where: {type: {_eq: "project"}}, order_by: {created_at: asc}) {
        id
        title
        description
        }
    }
`;