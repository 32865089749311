import { UPDATE_PROJECT_MUTATION } from '@/graphql/projects.gql';
import { useProjectsStore } from '@/stores/projects.store';
import { useMutation } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import {
  Dispatch,
  Fragment,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import {
  CLPrimaryButton,
  CLPrimaryInput,
  CLSecondaryButton
} from '../ui-controls';
import CLButton from '../ui-controls/default-ui-controls/button';
import { LoadingSpinWhiteCustom } from '../utils/loading.component';
import { shallow } from 'zustand/shallow';

interface PopupProps {
  projectId: string;
  projectName?: string;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function EditProjectPopupComponent({
  projectId,
  isOpen,
  setOpen,
  ...props
}: PopupProps) {
  // Variables
  // States
  const [projectName, setProjectName] = useState<string>(
    props.projectName || ''
  );
  const [description, setDescription] = useState<string>('');
  const [isProjectLoading, setProjectLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Store
  const [updateProject] = useProjectsStore(s => [s.updateProject], shallow);

  // Query
  const [updateProjectMutation] = useMutation(UPDATE_PROJECT_MUTATION);

  useEffect(() => {
    // Reset fields
    if (isOpen) {
      setProjectName(props?.projectName || '');
    }
  }, [isOpen, props?.projectName]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateProjectById();
    }
  };

  function validate(): string | undefined {
    if (!projectName) {
      return 'Please enter project name';
    }
  }

  async function updateProjectById() {
    // Start create loading
    setProjectLoading(true);

    // Reset error message
    setErrorMessage('');

    // Do validation
    const validationError = validate();
    if (validationError) {
      // Show error message
      setErrorMessage(validationError);
      setProjectLoading(false);
      return;
    }

    const { data: _projectDetails, extensions: _projectExtentions } =
      await updateProjectMutation({
        variables: {
          projectId: projectId,
          set: {
            name: projectName
          }
        }
      });

    // Refresh UI
    updateProject(projectId, projectName);

    // Close popup
    setOpen(false);

    // Stop create loading
    setProjectLoading(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all">
                  {/* Header */}
                  <div className="flex justify-between items-center">
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Update project
                      </Dialog.Title>
                    </div>

                    <div>
                      <CLButton
                        className="p-2"
                        tabIndex={-1}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L8 6.58579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L9.41421 8L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8 9.41421L1.70711 15.7071C1.31658 16.0976 0.683417 16.0976 0.292893 15.7071C-0.0976311 15.3166 -0.0976311 14.6834 0.292893 14.2929L6.58579 8L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                            fill="black"
                            fillOpacity="0.5"
                          />
                        </svg>
                      </CLButton>
                    </div>
                  </div>

                  <div className="mt-4">
                    <CLPrimaryInput
                      type="text"
                      label="Project Name *"
                      value={projectName}
                      onKeyDown={handleKeyDown}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setProjectName(event.target.value);
                      }}
                    />
                  </div>
                  {/* <div className="mt-4">
                    <CLPrimaryTextarea
                      label="Description"
                      value={description}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setDescription(event.target.value);
                      }}
                    />
                  </div> */}

                  <div className="mt-4 flex flex-wrap items-center justify-between gap-2">
                    <div>
                      {errorMessage && (
                        <div className="text-red-400">{errorMessage}</div>
                      )}
                    </div>

                    <div className="flex gap-2">
                      <CLSecondaryButton
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </CLSecondaryButton>

                      <CLPrimaryButton
                        onClick={() => {
                          updateProjectById();
                        }}
                        disabled={isProjectLoading}
                      >
                        {isProjectLoading && (
                          <LoadingSpinWhiteCustom className="w-4 h-4"></LoadingSpinWhiteCustom>
                        )}
                        Update
                      </CLPrimaryButton>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
