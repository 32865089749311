import { useState } from 'react';
import { CLPrimaryButton } from '../../../ui-controls';
import AssignChecklistPopupComponent from './assign-checklist-popup.component';

export default function AssignChecklistButtonComponent({
  projectId,
  checklistId,
  checklistTitle,
  checklistVersionId
}: {
  projectId: string;
  checklistId: string;
  checklistTitle: string;
  checklistVersionId: string | undefined;
}) {
  // States
  const [isAssignChecklistPopupOpen, setIsAssignChecklistPopupOpen] =
    useState<boolean>(false);

  if (!projectId || !checklistId || !checklistVersionId) {
    return <></>;
  }

  return (
    <>
      <CLPrimaryButton
        className="gap-2 min-h-[38px]"
        title="Assign"
        onClick={() => {
          setIsAssignChecklistPopupOpen(true);
        }}
      >
        <svg
          className="w-4"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8.68155C8.71668 8.68155 7.65868 8.27295 6.82599 7.45575C5.9933 6.63854 5.57695 5.59813 5.57695 4.33449C5.57695 3.07084 5.9933 2.03251 6.82599 1.21949C7.65868 0.406496 8.71668 0 10 0C11.2833 0 12.3413 0.406496 13.174 1.21949C14.0067 2.03251 14.423 3.07084 14.423 4.33449C14.423 5.59813 14.0067 6.63854 13.174 7.45575C12.3413 8.27295 11.2833 8.68155 10 8.68155ZM18.4616 18.6667H1.53844C1.11666 18.6667 0.754813 18.5185 0.452901 18.2222C0.150967 17.9259 0 17.5708 0 17.1568V16.183C0 15.4801 0.189745 14.8678 0.569235 14.346C0.948725 13.8243 1.44189 13.4234 2.04874 13.1432C3.45214 12.5309 4.80499 12.0695 6.10729 11.7592C7.4096 11.4488 8.70704 11.2937 9.9996 11.2937C11.2922 11.2937 12.5863 11.4522 13.882 11.7692C15.1778 12.0863 16.5246 12.5473 17.9224 13.1521C18.553 13.4315 19.0569 13.8308 19.4341 14.3499C19.8114 14.8691 20 15.4801 20 16.183V17.1568C20 17.5708 19.849 17.9259 19.5471 18.2222C19.2452 18.5185 18.8833 18.6667 18.4616 18.6667ZM1.5128 17.182H18.4872V16.183C18.4872 15.8425 18.3774 15.5162 18.1577 15.2041C17.938 14.8921 17.6513 14.6471 17.2974 14.4693C15.9863 13.8436 14.7496 13.4053 13.5873 13.1545C12.4251 12.9037 11.2293 12.7783 10 12.7783C8.77071 12.7783 7.56595 12.9037 6.38572 13.1545C5.20549 13.4053 3.97094 13.8436 2.68204 14.4693C2.3282 14.6471 2.04486 14.8921 1.83204 15.2041C1.61922 15.5162 1.5128 15.8425 1.5128 16.183V17.182ZM10 7.19692C10.8325 7.19692 11.5256 6.92514 12.0795 6.38157C12.6333 5.83803 12.9102 5.15776 12.9102 4.34078C12.9102 3.52379 12.6333 2.84353 12.0795 2.29998C11.5256 1.75644 10.8325 1.48467 10 1.48467C9.16753 1.48467 8.47437 1.75644 7.92053 2.29998C7.36668 2.84353 7.08976 3.52379 7.08976 4.34078C7.08976 5.15776 7.36668 5.83803 7.92053 6.38157C8.47437 6.92514 9.16753 7.19692 10 7.19692Z"
            fill="black"
          />
        </svg>
        <span>Assign</span>
      </CLPrimaryButton>

      <AssignChecklistPopupComponent
        projectId={projectId}
        checklistId={checklistId}
        checklistTitle={checklistTitle}
        checklistVersionId={checklistVersionId}
        isOpen={isAssignChecklistPopupOpen}
        setIsOpen={setIsAssignChecklistPopupOpen}
      />
    </>
  );
}
