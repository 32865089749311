import { CLPrimaryCustomInput } from '@/components/ui-controls';
import { useAnalyticsChecklistStore } from '@/stores/responses/responses-checklist.store';
import Link from 'next/link';
import { shallow } from 'zustand/shallow';
import BuilderLayoutTabs from '../builder-layout-tabs.component';

export default function ResponsesMenuComponent() {
  // Variables

  // Store
  const [checklist, hasChecklistUpdatePermission] = useAnalyticsChecklistStore(
    state => [state.checklist, state.hasChecklistUpdatePermission],
    shallow
  );

  return (
    <>
      <div className="border-b-[1px] border-solid border-black/10">
        <div className="w-full h-full px-4">
          <div className="flex items-center justify-between gap-4 h-14">
            {/* Left */}
            <div className="flex items-center gap-3 md:w-[25%]">
              {/* Back */}
              <Link
                title="Back"
                href={'/projects/' + (checklist?.project_id || '')}
                className="flex items-center justify-center min-w-[40px] w-10 h-10 rounded-full bg-white border-[1px] border-solid border-black/20"
              >
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.5649 0.43451C7.87732 0.746929 7.87732 1.25346 7.5649 1.56588L2.13059 7.0002L7.5649 12.4345C7.87732 12.7469 7.87732 13.2535 7.5649 13.5659C7.25248 13.8783 6.74595 13.8783 6.43353 13.5659L0.433533 7.56588C0.121114 7.25346 0.121114 6.74693 0.433533 6.43451L6.43353 0.43451C6.74595 0.12209 7.25248 0.12209 7.5649 0.43451Z"
                    fill="black"
                  />
                </svg>
              </Link>

              {/* Title */}
              <div className="w-full">
                <CLPrimaryCustomInput
                  type="text"
                  style={{ padding: '8px 16px', maxWidth: '350px' }}
                  className="bg-transparent text-sm w-full"
                  value={checklist?.title || ''}
                  disabled={true}
                />
              </div>
            </div>

            {/* Center - Desktop */}
            <div className="hidden md:block h-full">
              {/* Tabs */}
              <div className="h-full">
                <BuilderLayoutTabs />
              </div>
            </div>

            {/* Right */}
            <div className="flex items-center justify-end gap-3 md:w-[25%]">
              {/* Empty */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
