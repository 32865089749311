import { gql } from "@apollo/client";

export const GET_RUN_CHECKLIST_COMMENTS_BY_CHECKLIST_ID_QUERY = gql`
query getRunChecklistComments($runChecklistId: uuid!) {
    run_checklist_comments(where: {run_checklist_id: {_eq: $runChecklistId}}) {
      id
      text
      created_at
      user {
        id
        displayName
        email
        avatarUrl
      }
      run_checklist_comment_files {
        id
        url
        org_file {
          file {
            id
            name
            mimeType
            size
          }
        }
      }
    }
}  
`;

export const INSERT_RUN_CHECKLIST_COMMENT_MUTATION = gql`
  mutation InsertRunChecklistCommentMutation($runChecklistComments: [run_checklist_comments_insert_input!]!, $orgFiles: [org_files_insert_input!]!, $runChecklistCommentFiles: [run_checklist_comment_files_insert_input!]!) {
    insert_run_checklist_comments(objects: $runChecklistComments) {
      affected_rows
    }
    insert_org_files(objects: $orgFiles) {
      affected_rows
    }
    insert_run_checklist_comment_files(objects: $runChecklistCommentFiles) {
      affected_rows
    }
  }
`;

export const DELETE_RUN_CHECKLIST_COMMENT_BY_ID_MUTATION = gql`
mutation DeleteRunChecklistCommentByIdMutation($runChecklistCommentId: uuid!) {
  delete_run_checklist_comment_files(where: {run_checklist_comment_id: {_eq: $runChecklistCommentId}}) {
    affected_rows
  }

  delete_run_checklist_comments_by_pk(id: $runChecklistCommentId) {
    id
  }
}
`;

export const GET_PUBLIC_RUN_CHECKLIST_COMMENTS_BY_CHECKLIST_ID_QUERY = gql`
query GetPublicRunChecklistComments($checklistId: uuid!) {
  public_run_checklist_comments(where: {public_run_checklist_id: {_eq: $checklistId}}) {
    id
    text
    public_run_checklist_id
    updated_at
    updated_by
    created_by
    created_at
    org_guest_user {
      created_at
      email
      first_name
      full_name
      id
      last_name
      org_id
    }
    public_run_checklist_comment_files {
      id
      url
      guest_org_file_id
      public_run_checklist_comment_id
      org_guest_file {
        file {
          name
          mimeType
          size
        }
      }
    }
  }
}
`;