interface Props {
  children: React.ReactNode;
}
export default function AuthLayout({ children }: Props) {
  return (
    <div className="grid h-screen">
      {/* Left */}
      <div className="hidden">
        {/* Default content */}
        <div className="h-full flex items-center justify-center px-4 bg-[#FFD95B]">
          <div className="relative">
            {/* Quote icon */}
            <div className="absolute" style={{ top: '-10rem', left: '2rem' }}>
              <svg
                width="150"
                height="113"
                viewBox="0 0 150 113"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M72.8 112.8L123.6 0H150L118 112.8H72.8ZM0 112.8L51.6 0H77.6L45.2 112.8H0Z"
                  fill="black"
                />
              </svg>
            </div>

            {/* Content */}
            <div className="text-5xl font-medium leading-snug text-center">
              With the help of checklist.gg, organizations can avoid errors and
              consistently achieve <br /> their goals.
            </div>
          </div>
        </div>
      </div>

      {/* Right */}
      <div>{children}</div>
    </div>
  );
}
