import { RunComponentCommonProps } from '@/types/run-components.type';

export default function RunImageGroupComponent({
  componentMapping
}: RunComponentCommonProps) {
  return (
    <div>
      {componentMapping?.component_image_group?.component_images?.map(
        (component_image, index) => (
          <div key={index} className="flex items-center justify-center">
            <img
              className="rounded-lg max-w-full"
              src={component_image.url}
            ></img>
          </div>
        )
      )}
    </div>
  );
}
