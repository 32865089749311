import { gql } from "@apollo/client";

// checklist(where: {id: {_eq: $id}, _and: {is_deleted: {_is_null: true}, _and: {project: {is_deleted: {_is_null: true}}}}}) {
export const GET_CHECKLIST_BY_ID_QUERY = gql`
  query GetChecklistById($id: uuid!) {
    checklist(where: {id: {_eq: $id}}) {
      id
      title
      description
      parent_id
      checklist_id
      project_id
      order_number
      agent_manager_id
      updated_at
      checklist_version_id
      checklist_version_published_at
      is_deleted
      checklistsByChecklistId {
        id
        title
        description
        parent_id
        order_number
      }
      project {
        id
        name
        org_id
      }
      checklist_status_indicators {
        id
        name
        order_number
      }
    }
  }
`;

// checklist(where: {id: {_eq: $id}, _and: {is_deleted: {_is_null: true}, _and: {project: {is_deleted: {_is_null: true}}}}}) {
export const GET_ROOT_CHECKLIST_BY_ID_QUERY = gql`
  query GetChecklistById($id: uuid!) {
    checklist(where: {id: {_eq: $id}}) {
      id
      title
      description
      parent_id
      checklist_id
      project_id
      order_number
      agent_manager_id
      updated_at
      checklist_version_id
      checklist_version_published_at
      is_deleted
      project {
        id
        name
        org_id
      }
    }
  }
`;

export const CREATE_MULTIPLE_CHECKLIST_MUTATION = gql`
  mutation CreateMultipleChecklist($checklist: [checklist_insert_input!]!) {
    insert_checklist(
      objects: $checklist
    ) {
      returning {
        id
        title
        parent_id
        updated_at
        userByCreatedBy {
            id
            displayName
            email
        }
      }
    }
  }
`;

export const CREATE_MULTIPLE_CHECKLIST_WITH_STATUS_INDICATORS_MUTATION = gql`
  mutation CreateMultipleChecklistWithStatusIndicators($checklist: [checklist_insert_input!]!, $checklist_status_indicators: [checklist_status_indicators_insert_input!]!) {
    insert_checklist(
      objects: $checklist
    ) {
      returning {
        id
      }
    }

    insert_checklist_status_indicators(
      objects: $checklist_status_indicators
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_CHECKLIST_MUTATION = gql`
  mutation UpdateChecklist($id: uuid!, $checklist: checklist_set_input!) {
    update_checklist_by_pk (
      pk_columns: {id: $id}
      _set: $checklist
    ) {
      id
      updated_at
      checklist_version_published_at
    }
  }
`;

export const CREATE_OR_UPDATE_CHECKLIST_MULTIPLE_MUTATION = gql`
  mutation CreateOrUpdateMultipleChecklist($checklist: [checklist_insert_input!]!) {
    insert_checklist(
      objects: $checklist,
      on_conflict: {
        constraint: checklist_pkey,
        update_columns: [
          order_number,
          parent_id
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_SINGLE_CHECKLIST_BY_ID_MUTATION = gql`
  mutation DeleteChecklistById($id: uuid!) {
    delete_checklist_by_pk (
      id: $id
    ) {
      id
      title
    }
  }
`;

export const DELETE_CHECKLIST_BY_MULTIPLE_IDS_MUTATION = gql`
  mutation DeleteChecklistByMultipleIds($ids: [uuid!]!) {
    delete_checklist_component_mapping(where: {checklist_id: { _in: $ids }}) {
      affected_rows
    }
    delete_component_file(where: {component_file_group: {checklist_component_mappings: {checklist_id: { _in: $ids }}}}) {
      affected_rows
    }
    delete_component_image(where: {component_image_group: {checklist_component_mappings: {checklist_id: { _in: $ids }}}}) {
      affected_rows
    }
    delete_component_image_group(where: {checklist_component_mappings: {checklist_id: { _in: $ids }}}) {
      affected_rows
    }
    delete_component_file_group(where: {checklist_component_mappings: {checklist_id: { _in: $ids }}}) {
      affected_rows
    }
    delete_component_text(where: {checklist_component_mappings: {checklist_id: { _in: $ids }}}) {
      affected_rows
    }
    delete_component_embed(where: {checklist_component_mappings: {checklist_id: { _in: $ids }}}) {
      affected_rows
    }

    delete_checklist(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;

export const SOFT_DELETE_CHECKLIST_BY_ID_MUTATION = gql`
  mutation SoftDeleteChecklistByIdMutation($checklistId: uuid!, $set: checklist_set_input!) {
    update_checklist_by_pk (
      pk_columns: {id: $checklistId}
      _set: $set
    ) {
      id
    }
  }
`;

export const GET_CHECKLIST_BY_ID_FOR_PUBLISH_CHECKLIST_QUERY = gql`
  query GetChecklistById($id: uuid!) {
    checklist(where: {id: {_eq: $id}}) {
      id
      title
      description
      parent_id
      checklist_id
      project_id
      order_number
      due_at
      priority
      status
      reviewed_by
      approved_by
      time_estimate
      checklistsByChecklistId {
        id
        title
        description
        parent_id
        checklist_id
        project_id
        order_number
        due_at
        priority
        status
        reviewed_by
        approved_by
        time_estimate
        checklist_component_mappings(order_by: {order_number: asc}) {
          order_number
          component_type
          component_text {
            content
          }
          component_image_group {
            name
            component_images {
              url
              alt_text
              org_file_id
            }
          }
          component_file_group {
            name
            component_files {
              url
              org_file_id
            }
          }
          component_embed {
            url
          }
        }
      }
      checklist_status_indicators(order_by: {order_number: asc}) {
        name
        order_number
      }
    }
  }
`;

export const PUBLISH_CHECKLIST_MUTATION = gql`
  mutation PublishChecklist(
    $publishableChecklist: [checklist_insert_input!]!,
    $newChecklistVersion: checklist_version_insert_input!,
    $checklistId: uuid!,
    $updatedSourceChecklist: checklist_set_input!,
    $component_text: [component_text_insert_input!]!,
    $component_image_group: [component_image_group_insert_input!]!,
    $component_file_group: [component_file_group_insert_input!]!,
    $component_embed: [component_embed_insert_input!]!,
    $component_images: [component_image_insert_input!]!,
    $component_files: [component_file_insert_input!]!,
    $checklist_component_mapping: [checklist_component_mapping_insert_input!]!,
    $checklist_status_indicators: [checklist_status_indicators_insert_input!]!) {
    insert_checklist(objects: $publishableChecklist) {
      affected_rows
    }

    insert_checklist_version_one(object: $newChecklistVersion) {
      id
    }

    update_checklist_by_pk(pk_columns: {id: $checklistId}, _set: $updatedSourceChecklist) {
      id
      updated_at
      checklist_version_published_at
      checklist_version_id
    }

    insert_component_text(
        objects: $component_text
    ) {
        affected_rows
    }
    
    insert_component_image_group(
        objects: $component_image_group
    ) {
        affected_rows
    }
    
    insert_component_file_group(
        objects: $component_file_group
    ) {
        affected_rows
    }
    
    insert_component_embed(
        objects: $component_embed
    ) {
        affected_rows
    }

    insert_component_file(
        objects: $component_files
    ) {
        affected_rows
    }

    insert_component_image(
        objects: $component_images
    ) {
        affected_rows
    }

    insert_checklist_component_mapping(
      objects: $checklist_component_mapping
    ) {
        affected_rows
    }

    insert_checklist_status_indicators(objects: $checklist_status_indicators) {
      affected_rows
    }
  }
`

export const GET_CHECKLIST_VERSION_BY_ID_QUERY = gql`
  query GetChecklistVersionById($id: uuid!, $checklistId: uuid!) {
    checklist_version(where: {id: {_eq: $id}, checklist_id: {_eq: $checklistId}}) {
      id
      checklistByVersionChecklistId {
        task_id
        checklistsByChecklistId {
          task_id
        }
      }
    }
  }
`;