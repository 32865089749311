import { ChecklistTree } from '@/components/checklist/checklist-by-id.component';
import { Checklist } from '@/types/checklist.type';
import { create } from 'zustand';

interface ChecklistState {
    orgId: string | undefined;
    projectId: string | undefined;
    checklistId: string | undefined;
    checklist: Checklist | undefined;
    checklistTree: ChecklistTree | undefined;
    selectedChecklist: Checklist | undefined;
    selectedChecklistId: string | undefined;
    hasChecklistUpdatePermission: boolean;
    checklistInstructionsMappingObject: { [key: string]: boolean };
    initChecklist: (checklist: Checklist, checklistTree: ChecklistTree) => void;
    setChecklistTree: (checklistTree: ChecklistTree) => void;
    setSelectedChecklist: (checklist: Checklist | undefined) => void;
    setHasChecklistUpdatePermission: (hasUpdateChecklistPermission: boolean) => void;
    reset: () => void;
    updateChecklistVersionId: (checklistVersionId: string) => void;
    setChecklistInstructionsMappingObject: (runChecklistInsturctionsMappingObject: { [key: string]: boolean }) => void;
}

export const useChecklistStore = create<ChecklistState>((set, get) => ({
    orgId: undefined,
    projectId: undefined,
    checklistId: undefined,
    checklist: undefined,
    checklistTree: undefined,
    selectedChecklist: undefined,
    selectedChecklistId: undefined,
    hasChecklistUpdatePermission: false,
    checklistInstructionsMappingObject: {},
    initChecklist: (checklist, checklistTree) => {
        const orgId = checklist?.project?.org_id;
        const projectId = checklist?.project?.id;
        const checklistId = checklist?.id;

        set((state) => ({
            orgId,
            projectId,
            checklistId,
            checklist,
            checklistTree
        }))
    },
    setChecklistTree: (checklistTree: ChecklistTree) => {
        set({ checklistTree })
    },
    setSelectedChecklist: (selectedChecklist: Checklist | undefined) => {
        if (selectedChecklist && selectedChecklist?.id) {
            set((state) => ({
                selectedChecklist: selectedChecklist,
                selectedChecklistId: selectedChecklist?.id
            }))
        } else {
            set({ selectedChecklist });
        }
    },
    setHasChecklistUpdatePermission: (hasChecklistUpdatePermission: boolean) => {
        set({ hasChecklistUpdatePermission })
    },
    reset: () => {
        set({
            orgId: undefined,
            projectId: undefined,
            checklistId: undefined,
            checklist: undefined,
            checklistTree: undefined,
            selectedChecklist: undefined,
            selectedChecklistId: undefined,
            hasChecklistUpdatePermission: false,
            checklistInstructionsMappingObject: {}
        })

    },
    updateChecklistVersionId: (checklistVersionId: string) => {
        const { checklist } = get();
        if (checklist) {
            set((state) => ({
                checklist: {
                    ...checklist,
                    checklist_version_id: checklistVersionId
                }
            }))
        }
    },
    setChecklistInstructionsMappingObject: (checklistInstructionsMappingObject: { [key: string]: boolean }) => {
        set(state => ({ checklistInstructionsMappingObject: { ...checklistInstructionsMappingObject } }));
    }
}));
